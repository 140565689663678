import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const MobileViewChecker: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobileView = () => {
      // Use a more lenient mobile detection approach
      const width = window.innerWidth;
      const height = window.innerHeight;
      
      // Consider it mobile if width is smaller, but also check for PWA/mobile-like environments
      const mobileDetected = width <= 768 || 
        // Check for typical mobile screen aspect ratios
        (width < height && (width <= 812 || height <= 812)) ||
        // Check for PWA environment
        window.matchMedia('(max-width: 768px)').matches ||
        // Additional checks for mobile-like environments
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      
      setIsMobile(mobileDetected);
    };

    // Initial check
    checkMobileView();

    // Add event listener for resize
    window.addEventListener('resize', checkMobileView);

    // Cleanup listener
    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);

  if (isMobile) {
    return (
      <div className="h-screen w-screen overflow-hidden">
        {children}
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100 p-4 text-center">
      <div className="max-w-md bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-2xl font-bold mb-4">📱 Mobile View Only</h1>
        <p className="mb-6 text-gray-600">
          This application is designed for mobile devices only. 
          Please use a smartphone or resize your browser window 
          to be less than 768px wide.
        </p>
        <div className="flex flex-col space-y-4">
          <div className="flex items-center justify-center space-x-4">
            <span className="text-4xl">🚫</span>
            <span className="text-4xl">💻</span>
            <span className="text-4xl">❌</span>
          </div>
          <button 
            onClick={() => navigate('/')}
            className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileViewChecker;