import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { api } from '../services/api';

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredRole?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, requiredRole }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(null);
  const [userRole, setUserRole] = React.useState<string | null>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          setIsAuthenticated(false);
          return;
        }

        const response = await api.getUserProfile();
        setIsAuthenticated(true);
        // Convert role to lowercase for consistent comparison
        setUserRole(response.data.role.toLowerCase());
      } catch (err) {
        // Check if the error is a 401 Unauthorized error (token expired)
        if (axios.isAxiosError(err) && (err as AxiosError)?.response?.status === 401) {
          setIsAuthenticated(false);
          localStorage.removeItem('access_token');
          navigate('/login', { replace: true });
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem('access_token');
        }
      }
    };

    checkAuthentication();
  }, [navigate]);

  // Loading state
  if (isAuthenticated === null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Not authenticated
  if (isAuthenticated === false) {
    return <Navigate to="/login" replace />;
  }

  // Check role if requiredRole is specified
  if (requiredRole && userRole !== requiredRole.toLowerCase()) {
    // Redirect to appropriate dashboard based on role
    if (userRole === 'user') return <Navigate to="/dashboard/user" replace />;
    if (userRole === 'maintainer') return <Navigate to="/dashboard/maintainer" replace />;
    if (userRole === 'super_maintainer') return <Navigate to="/dashboard/super-maintainer" replace />;
    if (userRole === 'admin') return <Navigate to="/dashboard/admin" replace />;
    if (userRole === 'service') return <Navigate to="/dashboard/service" replace />;
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
