import React, { useState, useEffect } from 'react';
import { api } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { VideoBackground } from '../components/VideoBackground';
import { toast, Toaster } from 'react-hot-toast';
import { User, Phone, Mail, MapPin, Lock, CheckCircle, Calendar } from 'lucide-react';
import { InputValidators } from '../utils/validationUtils';

// interface Maintainer {
//   id: number;
//   name: string;
// }

export const RegisterPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone_number: '',
    email: '',
    address: '',
    password: '',
    confirm_password: '',
    maintainer_id: null as number | null,
    birthday: ''
  });
  // const [maintainers, setMaintainers] = useState<Maintainer[]>([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchMaintainers = async () => {
  //     try {
  //       const response = await api.getMaintainersList();
  //       setMaintainers(response.data);
  //     } catch (error) {
  //       toast.error('Failed to fetch maintainers. Please try again later.');
  //     }
  //   };

  //   fetchMaintainers();
  // }, []);

  const validateForm = () => {
    // Name validation
    const nameError = InputValidators.validateName(formData.name);
    if (nameError) {
      toast.error(nameError);
      return false;
    }
  
    // Phone validation
    const phoneError = InputValidators.validatePhone(formData.phone_number);
    if (phoneError) {
      toast.error(phoneError);
      return false;
    }
  
    // Email validation
    const emailError = InputValidators.validateEmail(formData.email);
    if (emailError) {
      toast.error(emailError);
      return false;
    }
  
    // Address validation
    const addressError = InputValidators.validateAddress(formData.address);
    if (addressError) {
      toast.error(addressError);
      return false;
    }
  
    // Password validation
    const passwordError = InputValidators.validatePassword(formData.password);
    if (passwordError) {
      toast.error(passwordError);
      return false;
    }
  
    // Confirm password validation
    if (formData.password !== formData.confirm_password) {
      toast.error('Passwords do not match');
      return false;
    }
  
    // Birthday validation
    const birthdayError = InputValidators.validateBirthday(formData.birthday);
    if (birthdayError) {
      toast.error(birthdayError);
      return false;
    }
  
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      // Remove confirm_password before sending to API
      const { confirm_password, ...submitData } = formData;
      
      await api.register(submitData);
      
      // Success toast with a delay before navigation
      toast.success('Welcome to Youthfire! You are part of our family now.', {
        duration: 2000,
        position: 'top-center'
      });

      // Navigate after a short delay to allow toast to be seen
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error: any) {
      // Check if error response exists and has a message
      const errorMessage = error.response?.data?.detail || 
                           error.response?.data?.message || 
                           'Registration failed. Please try again.';
      
      toast.error(errorMessage, {
        position: 'top-center'
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <VideoBackground videoSrc="/video.mp4">
      <Toaster 
        toastOptions={{
          success: {
            style: {
              background: '#4CAF50',
              color: 'white',
            },
          },
          error: {
            style: {
              background: '#F44336',
              color: 'white',
            },
          },
        }}
      />
      <div className="h-screen flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-xl shadow-2xl border border-gray-200">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-2">Create Account</h2>
            <p className="text-gray-500">Join Youthfire Community</p>
          </div>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="text-gray-400" size={20} />
              </div>
              <input 
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required
              />
            </div>

            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone className="text-gray-400" size={20} />
              </div>
              <input 
                type="tel"
                name="phone_number"
                placeholder="Phone Number"
                value={formData.phone_number}
                onChange={handleChange}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required
              />
            </div>

            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="text-gray-400" size={20} />
              </div>
              <input 
                type="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required
              />
            </div>
            <div className="relative mb-4">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="text-gray-400" size={20} />
              </div>
              <input
                type="date"
                name="birthday"
                placeholder="Birthday"
                value={formData.birthday}
                onChange={handleChange}
                max={new Date().toISOString().split('T')[0]} // Prevents future dates
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition appearance-none"
                required
              />
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin className="text-gray-400" size={20} />
              </div>
              <input 
                type="text"
                name="address"
                placeholder="Full Address"
                value={formData.address}
                onChange={handleChange}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required
              />
            </div>

            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="text-gray-400" size={20} />
              </div>
              <input 
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required
              />
            </div>

            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <CheckCircle className="text-gray-400" size={20} />
              </div>
              <input 
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                value={formData.confirm_password}
                onChange={handleChange}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required
              />
            </div>

            {/* <div className="mb-4">
              <label className="block text-gray-700 mb-2">Ketua MK</label>
              <select
                name="maintainer_id"
                value={formData.maintainer_id || ''}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
              >
                <option value="">Select Ketua MK (Optional)</option>
                {maintainers.map((maintainer) => (
                  <option key={maintainer.id} value={maintainer.id}>
                    {maintainer.name}
                  </option>
                ))}
              </select>
            </div> */}
    
            <button 
              type="submit" 
              className="w-full bg-red-900 text-white p-3 rounded-lg hover:bg-red-800 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
            >
              Create Account
            </button>
          </form>

          <div className="text-center">
            <p className="text-gray-600">
              Already have an account? 
              <span 
                onClick={() => navigate('/login')}
                className="text-red-900 ml-1 cursor-pointer hover:underline"
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </div>
    </VideoBackground>
  );
};

export default RegisterPage;