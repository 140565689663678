import React, { useState, useEffect } from 'react';
import { Building, Clock, LogOut, Check, X } from 'lucide-react';
import { api } from '../../services/api';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

interface Maintainer {
  id: number;
  name: string;
}

interface MeetingRoomRequest {
  id: number;
  title: string;
  description: string;
  location: string;
  meeting_datetime: string;
  maintainer_id: number;
  status: string;
  response: string | null;
  created_at: string;
  expires_at: string;
}

interface EnrichedMeetingRoomRequest extends MeetingRoomRequest {
  maintainerName?: string;
}

const ServiceDashboard: React.FC = () => {
  const [requests, setRequests] = useState<EnrichedMeetingRoomRequest[]>([]);
  const [maintainers, setMaintainers] = useState<Maintainer[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<number | null>(null);
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [requestsResponse, maintainersResponse] = await Promise.all([
        api.getServiceMeetingRequests(),
        api.getMaintainersList(),
      ]);

      const maintainersData = maintainersResponse.data;
      setMaintainers(maintainersData);

      const enrichedRequests = requestsResponse.data.map((request: MeetingRoomRequest) => {
        const maintainer = maintainersData.find((m: Maintainer) => m.id === request.maintainer_id);
        return {
          ...request,
          maintainerName: maintainer?.name,
        };
      });

      setRequests(enrichedRequests);
    } catch (error) {
      toast.error('Failed to load data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
    toast.success('Logged out successfully');
  };

  const handleSubmitResponse = async (requestId: number) => {
    try {
      await api.answerServiceRequest(requestId, { response });
      await fetchData();
      setSelectedRequest(null);
      setResponse('');
      toast.success('Response submitted successfully');
    } catch (error) {
      toast.error('Failed to submit response');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-4 overflow-y-auto" style={{ maxHeight: 'calc(100vh)' }}>
      <Toaster position="top-right" />

      <div className="bg-white shadow-md p-4 flex justify-between items-center">
        <div className="flex items-center">
          <Building className="text-blue-500 mr-3" size={32} />
          <h1 className="text-2xl font-bold text-gray-800">Service Dashboard</h1>
        </div>
        <button
          onClick={handleLogout}
          className="flex items-center gap-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
        >
          <LogOut size={20} />
          Logout
        </button>
      </div>

      <div className="max-w-4xl mx-auto p-4 space-y-4">
        {requests.length === 0 ? (
          <div className="bg-white rounded-lg shadow-md p-8 text-center">
            <Building className="text-gray-300 mx-auto mb-4" size={64} />
            <p className="text-gray-500">No meeting room requests available</p>
          </div>
        ) : (
          requests.map((request) => (
            <div
              key={request.id}
              className={`bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6 ${
                selectedRequest === request.id ? 'border-2 border-blue-500' : ''
              }`}
            >
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">
                    {request.title}{' '}
                    {request.maintainerName ? `from ${request.maintainerName}` : `#${request.id}`}
                  </h3>
                  <p className="text-gray-600 mb-2">{request.description}</p>
                  <p className="text-gray-600 mb-2">Location: {request.location}</p>
                  <div className="flex items-center text-sm text-gray-500 space-x-4">
                    <div className="flex items-center">
                      <Clock size={16} className="mr-1" />
                      <span>
                        Meeting: {new Date(request.meeting_datetime).toLocaleString()}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock size={16} className="mr-1" />
                      <span>
                        Created: {new Date(request.created_at).toLocaleString()}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Clock size={16} className="mr-1" />
                      <span>
                        Expires: {new Date(request.expires_at).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    request.status === 'pending'
                      ? 'bg-yellow-100 text-yellow-700'
                      : request.status === 'approved'
                      ? 'bg-green-100 text-green-700'
                      : 'bg-red-100 text-red-700'
                  }`}
                >
                  {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                </span>
              </div>

              {request.response && (
                <div className="bg-gray-50 p-3 rounded-lg mb-4">
                  <p className="text-gray-600">
                    <span className="font-medium">Previous Response:</span> {request.response}
                  </p>
                </div>
              )}

              {request.status === 'pending' && (
                <div className="mt-4">
                  {selectedRequest === request.id ? (
                    <div className="space-y-3">
                      <textarea
                        value={response}
                        onChange={(e) => setResponse(e.target.value)}
                        placeholder="Enter your response..."
                        className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        rows={3}
                      />
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() => {
                            setSelectedRequest(null);
                            setResponse('');
                          }}
                          className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleSubmitResponse(request.id)}
                          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition disabled:bg-blue-300"
                          disabled={!response.trim()}
                        >
                          Submit Response
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => setSelectedRequest(request.id)}
                      className="text-blue-500 hover:text-blue-600 font-medium flex items-center gap-2"
                    >
                      <Check size={20} /> Respond to Request
                    </button>
                  )}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ServiceDashboard;