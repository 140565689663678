import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://127.0.0.1:8000/api/v1';

export const api = {
  register: async (userData: {
    name: string;
    phone_number: string;
    email: string;
    address: string;
    maintainer_id?: number | null;
    password: string;
  }) => {
    const registrationData = {
      ...userData,
      maintainer_id: userData.maintainer_id || null
    };
    return await axios.post(`${API_BASE_URL}/users/register`, registrationData);
  },

  login: async (credentials: {
    username: string;
    password: string;
  }) => {
    const formData = new FormData();
    formData.append('username', credentials.username);
    formData.append('password', credentials.password);
    formData.append('grant_type', 'password');
    formData.append('scope', '');
    formData.append('client_id', '');
    formData.append('client_secret', '');

    return await axios.post(`${API_BASE_URL}/token`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  },

  getUserProfile: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/me`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  getSermonNotes: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/users/maintainer-notes/`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getMeetingRoomInfo: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/users/meeting-room-info/`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  recordAbsence: async (absencetoken: string) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/users/record-absence/${absencetoken}`, {}, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getUserInvitations: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/users/invitations/`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  respondToInvitation: async (invitationId: number, response: boolean) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(
      `${API_BASE_URL}/users/respond-invitation/${invitationId}`, 
      null, 
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { response }
      }
    );
  },
  getMaintainersList: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/maintainers/list`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  getMaintainerProfile: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/me`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  // Meeting Room
  getMeetingRoomRequests: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/maintainer/meeting-room-requests`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  requestMeetingRoom: async (data: { 
    title: string;
    description: string;
    meeting_datetime: string;
    location: string;
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/maintainer/meeting-room/request`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  setMeetingRoomManually: async (data: {
    request: { 
      title: string;
      description: string;
      meeting_datetime: string;
      location: string;
    };
    response: { response: string };
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/maintainer/meeting-room/manual`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  // Notes
  getMaintainerNotes: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/maintainer/notes`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  createNote: async (data: { title: string; description: string }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/maintainer/notes`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  // User Management
  getMaintainedUsers: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/maintainer/my-users`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  updateUser: async (userId: number, data: {
    name: string;
    phone_number: string;
    birthday: string;
    address: string;
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.put(`${API_BASE_URL}/maintainer/users/${userId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  removeUser: async (userId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.delete(`${API_BASE_URL}/maintainer/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  resetUserPassword: async (userId: number, data: { new_password: string }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/maintainer/users/${userId}/reset-password`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  generateAbsenceToken: async (customToken?: string) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(
      `${API_BASE_URL}/maintainer/generate-absence-token`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: customToken ? { token: customToken } : undefined
      }
    );
  },

  createUser: async (data: {
    name: string;
    phone_number: string;
    birthday: string;
    address: string;
    password: string;
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/maintainer/create-user`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  // Soul Management
  getAvailableUsers: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/maintainer/available-users`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  inviteUser: async (userId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/maintainer/invite-user/${userId}`, null, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  // Maintainer Invitations
  getMaintainerInvitations: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/maintainers/invitations`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  respondToMaintainerInvitation: async (invitationId: number, accepted: boolean) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(
      `${API_BASE_URL}/maintainers/respond-invitation/${invitationId}`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { response: accepted }
      }
    );
  },
  getSuperMaintainerProfile: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/me`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getSuperMaintainedMaintainers: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/super-maintainer/my-maintainers`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  createMaintainer: async (data: {
    name: string;
    username: string;
    phone_number: string;
    email: string;
    birthday: string;
    password: string;
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/super-maintainer/create-maintainer`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  updateMaintainer: async (maintainerId: number, data: {
    name: string;
    phone_number: string;
    birthday: string;
    email: string;
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.put(`${API_BASE_URL}/super-maintainer/maintainers/${maintainerId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  removeMaintainer: async (maintainerId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.delete(`${API_BASE_URL}/super-maintainer/maintainers/${maintainerId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  resetMaintainerPassword: async (maintainerId: number, data: { new_password: string }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/super-maintainer/maintainers/${maintainerId}/reset-password`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getAvailableMaintainers: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/super-maintainer/available-maintainers`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  inviteMaintainer: async (maintainerId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/super-maintainer/invite-maintainer/${maintainerId}`, null, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getSuperMaintainerInvitations: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/super-maintainers/invitations`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  respondToSuperMaintainerInvitation: async (invitationId: number, accepted: boolean) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(
      `${API_BASE_URL}/super-maintainers/respond-invitation/${invitationId}`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { response: accepted }
      }
    );
  },

   // Admin specific methods
   getAdminProfile: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/me`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getMySuperMaintainers: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/admin/my-super-maintainers`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getSuperMaintainerDetail: async (superMaintainerId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/admin/super-maintainers/${superMaintainerId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  updateSuperMaintainer: async (superMaintainerId: number, data: {
    name: string;
    phone_number: string;
    email: string;
    birthday: string;
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.put(`${API_BASE_URL}/admin/super-maintainers/${superMaintainerId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  removeSuperMaintainer: async (superMaintainerId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.delete(`${API_BASE_URL}/admin/super-maintainers/${superMaintainerId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  resetSuperMaintainerPassword: async (superMaintainerId: number, data: { new_password: string }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(
      `${API_BASE_URL}/admin/super-maintainers/${superMaintainerId}/reset-password`,
      data,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
  },

  getAvailableSuperMaintainers: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/admin/available-super-maintainers`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  inviteSuperMaintainer: async (superMaintainerId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(
      `${API_BASE_URL}/admin/invite-super-maintainer/${superMaintainerId}`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
  },

  getServiceMeetingRequests: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/service/meeting-room-requests`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  
  answerServiceRequest: async (requestId: number, data: { response: string }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/service/answer-request/${requestId}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getTodaysBirthdays: async () => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/today-birthday`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  createSuperMaintainer: async (data: {
    name: string;
    username: string;
    phone_number: string;
    email: string;
    birthday: string;
    password: string;
  }) => {
    const token = localStorage.getItem('access_token');
    return await axios.post(`${API_BASE_URL}/admin/create-super-maintainer`, data, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
    
  deleteUser: async (userId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.delete(`${API_BASE_URL}/delete-users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getUserAbsenceCount: async (userId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/users/absence-count/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  getMaintainerReport: async (startDate: string, endDate: string) => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/super-maintainer/maintainer-report`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { start_date: startDate, end_date: endDate }
    });
  },
  getUser: async (userId: number) => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  getAllMaintainersReport: async (startDate: string, endDate: string) => {
    const token = localStorage.getItem('access_token');
    return await axios.get(`${API_BASE_URL}/admin/all-maintainers-report`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { start_date: startDate, end_date: endDate }
    });
  },
};
