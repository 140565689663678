import React, { useState, useEffect } from 'react';
import { 
  Home, 
  User,
  Users,
  UserPlus,
  Edit2,
  Trash2,
  Key,
  ChevronDown,
  ChevronUp,
  Quote,
  Shield,
  MapPin,
  Phone,
  IdCard,
  UserCircle2,
  LogOut,
  Building,
  Flame,
  ShieldIcon,
  Cake,
  Clock,
  BarChart2
} from 'lucide-react';
import { 
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    Legend,
    ResponsiveContainer 
  } from 'recharts';
import { api } from '../../services/api';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BirthdaySlider from '../../components/BirthdaySlider';
import { InputValidators } from '../../utils/validationUtils';
import EnhancedLoadingScreen from '../../components/EnhancedLoadingScreen';

// Enhanced DashboardCard component
interface MaintainerReport {
    maintainer_id: number;
    maintainer_name: string;
    meetings: Array<{
      id: number;
      title: string;
      description: string;
      meeting_datetime: string;
      location: string;
      maintainer_id: number;
      status: string;
      response: string;
      created_at: string;
      expires_at: string;
      maintainer_name: string;
    }>;
    absences: Array<{
      user_id: number;
      recorded_at: string;
    }>;
  }
  
  interface SuperMaintainerReport {
    super_maintainer_id: number;
    super_maintainer_name: string;
    maintainers_report: MaintainerReport[];
  }

export interface Birthday {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string | null;
  maintainer?: {
    name: string;
  };
  super_maintainer?: {
    name: string;
  };
  admin?: {
    name: string;
  };
  role: string;
  service_info: any | null;
}

interface DashboardCardProps {
  children: React.ReactNode;
  className?: string;
}

// Update the DashboardCard component with proper typing
const DashboardCard: React.FC<DashboardCardProps> = ({ children, className = '' }) => (
  <div className={`bg-white rounded-xl shadow-lg p-6 transition-all duration-300 
    hover:shadow-xl hover:border-l-4 hover:border-[#FF4B1F] ${className}`}>
    {children}
  </div>
);

// Interfaces
interface AdminProfile {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string;
  role: string;
}

interface BibleVerse {
  bookname: string;
  chapter: string;
  verse: string;
  text: string;
}

interface SuperMaintainer {
  id: number;
  name: string;
  username: string;
  phone_number: string;
  email: string;
  birthday: string;
  maintainers: Array<{
    id: number;
    name: string;
    username: string;
    phone_number: string;
    email: string;
    users: Array<{
      id: number;
      name: string;
      phone_number: string;
      email: string;
    }>;
  }>;
}

interface AvailableSuperMaintainer {
  id: number;
  name: string;
  username: string;
  phone_number: string;
  email: string;
}

interface CreateSuperMaintainerData {
  name: string;
  username: string;
  phone_number: string;
  email: string;
  birthday: string;
  password: string;
}

const AdminDashboard: React.FC = () => {
  // State
  const [activeTab, setActiveTab] = useState('home');
  const [profile, setProfile] = useState<AdminProfile | null>(null);
  const [bibleVerse, setBibleVerse] = useState<BibleVerse | null>(null);
  const [superMaintainers, setSuperMaintainers] = useState<SuperMaintainer[]>([]);
  const [availableSuperMaintainers, setAvailableSuperMaintainers] = useState<AvailableSuperMaintainer[]>([]);
  const [expandedSuperMaintainerId, setExpandedSuperMaintainerId] = useState<number | null>(null);
  const [expandedMaintainerId, setExpandedMaintainerId] = useState<number | null>(null);
  
  // Modals state
  const [isEditSuperMaintainerModalOpen, setIsEditSuperMaintainerModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isAddSuperMaintainerModalOpen, setIsAddSuperMaintainerModalOpen] = useState(false);
  const [newSuperMaintainer, setNewSuperMaintainer] = useState<CreateSuperMaintainerData>({
    name: '',
    username: '',
    phone_number: '',
    email: '',
    birthday: '',
    password: ''
  });

  
  // Form states
  const [editingSuperMaintainer, setEditingSuperMaintainer] = useState<SuperMaintainer | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [removingId, setRemovingId] = useState<number | null>(null);
  const [birthdays, setBirthdays] = useState<Birthday[]>([]);
  const [reportData, setReportData] = useState<SuperMaintainerReport[]>([]);
  const [reportStartDate, setReportStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]
  );
  const [reportEndDate, setReportEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [expandedReportMaintainer, setExpandedReportMaintainer] = useState<number | null>(null);
  const [expandedReportSuperMaintainer, setExpandedReportSuperMaintainer] = useState<number | null>(null);
  const [graphData, setGraphData] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useState<Record<number, string>>({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (activeTab === 'report') {
      fetchReportData();
    }
  }, [activeTab, reportStartDate, reportEndDate]);
  
  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        const response = await api.getTodaysBirthdays();
        setBirthdays(response.data);
      } catch (error) {
        console.error('Failed to load birthdays:', error);
        toast.error('Failed to load birthdays');
      }
    };
    fetchBirthdays();
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch profile
        const profileResponse = await api.getAdminProfile();
        setProfile(profileResponse.data);

        // Fetch Bible verse
        const verseResponse = await axios.get('https://labs.bible.org/api/?passage=random&type=json&callback=myCallback', {
          transformResponse: [(data) => {
            const jsonMatch = data.match(/myCallback\((.*)\)/);
            return jsonMatch ? JSON.parse(jsonMatch[1]) : data;
          }]
        });
        
        if (Array.isArray(verseResponse.data) && verseResponse.data.length > 0) {
          setBibleVerse(verseResponse.data[0]);
        }

        // Fetch super maintainers
        const superMaintainersResponse = await api.getMySuperMaintainers();
        setSuperMaintainers(superMaintainersResponse.data);

        // Fetch available super maintainers
        const availableResponse = await api.getAvailableSuperMaintainers();
        setAvailableSuperMaintainers(availableResponse.data);
        
        setIsLoading(false);
      } catch (error) {
        toast.error('Failed to load dashboard data');
      }
    };

    fetchInitialData();
  }, []);

  const fetchReportData = async () => {
    try {
      const response = await api.getAllMaintainersReport(reportStartDate, reportEndDate);
      setReportData(response.data);
      
      // Process data for graph
      let mkCount = 0;
      let hangoutCount = 0;
      
      response.data.forEach((superMaintainer: SuperMaintainerReport) => {
        superMaintainer.maintainers_report.forEach((maintainer: MaintainerReport) => {
          maintainer.meetings.forEach((meetings) => {
            if (meetings.title.toLowerCase().includes('mk')) {
              mkCount++;
            } else if (meetings.title.toLowerCase().includes('hangout')) {
              hangoutCount++;
            }
          });
        });
      });
      
      setGraphData([
        { name: 'MK Sessions', count: mkCount, fill: '#FF4B1F' },
        { name: 'Hangouts', count: hangoutCount, fill: '#FFB75E' }
      ]);
    } catch (error) {
      toast.error('Failed to fetch report data');
    }
  };
  
  // Add useEffect for user details
  useEffect(() => {
    if (reportData.length > 0) {
      const userIds = new Set<number>();
      reportData.forEach(superMaintainer => {
        superMaintainer.maintainers_report.forEach(maintainer => {
          maintainer.absences.forEach(absence => {
            userIds.add(absence.user_id);
          });
        });
      });
      
      userIds.forEach(async (userId) => {
        try {
          const response = await api.getUser(userId);
          setUserDetails(prev => ({
            ...prev,
            [userId]: response.data.name
          }));
        } catch (error) {
          console.error(`Failed to fetch user ${userId}`);
        }
      });
    }
  }, [reportData]);

  if (isLoading) {
    return <EnhancedLoadingScreen />;
  }

  // Handlers
  const handleAddSuperMaintainer = async () => {
    const nameError = InputValidators.validateName(newSuperMaintainer.name);
    if (nameError) {
      toast.error(nameError);
      return;
    }
  
    const usernameError = InputValidators.validateName(newSuperMaintainer.username);
    if (usernameError) {
      toast.error(usernameError);
      return;
    }
  
    const phoneError = InputValidators.validatePhone(newSuperMaintainer.phone_number);
    if (phoneError) {
      toast.error(phoneError);
      return;
    }
  
    const emailError = InputValidators.validateEmail(newSuperMaintainer.email);
    if (emailError) {
      toast.error(emailError);
      return;
    }
  
    const birthdayError = InputValidators.validateBirthday(newSuperMaintainer.birthday);
    if (birthdayError) {
      toast.error(birthdayError);
      return;
    }
  
    const passwordError = InputValidators.validatePassword(newSuperMaintainer.password);
    if (passwordError) {
      toast.error(passwordError);
      return;
    }

    try {
      await api.createSuperMaintainer({
        name: newSuperMaintainer.name,
        username: newSuperMaintainer.username,
        phone_number: newSuperMaintainer.phone_number,
        email: newSuperMaintainer.email,
        birthday: newSuperMaintainer.birthday,
        password: newSuperMaintainer.password
      });
      const superMaintainersResponse = await api.getMySuperMaintainers();
      setSuperMaintainers(superMaintainersResponse.data);
      setIsAddSuperMaintainerModalOpen(false);
      setNewSuperMaintainer({
        name: '',
        username: '',
        phone_number: '',
        email: '',
        birthday: '',
        password: ''
      });
      toast.success('Ketua Rayon added successfully');
    } catch (error) {
      toast.error('Failed to add Ketua Rayon');
    }
  };
  
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
    toast.success('Logged out successfully');
  };

  const handleUpdateSuperMaintainer = async () => {
    if (!editingSuperMaintainer) return;

    const nameError = InputValidators.validateName(editingSuperMaintainer.name);
    if (nameError) {
      toast.error(nameError);
      return;
    }

    const phoneError = InputValidators.validatePhone(editingSuperMaintainer.phone_number);
    if (phoneError) {
      toast.error(phoneError);
      return;
    }

    const emailError = InputValidators.validateEmail(editingSuperMaintainer.email);
    if (emailError) {
      toast.error(emailError);
      return;
    }

    const birthdayError = InputValidators.validateBirthday(editingSuperMaintainer.birthday);
    if (birthdayError) {
      toast.error(birthdayError);
      return;
    }

    try {
      await api.updateSuperMaintainer(editingSuperMaintainer.id, {
        name: editingSuperMaintainer.name,
        phone_number: editingSuperMaintainer.phone_number,
        email: editingSuperMaintainer.email,
        birthday: editingSuperMaintainer.birthday
      });
      const superMaintainersResponse = await api.getMySuperMaintainers();
      setSuperMaintainers(superMaintainersResponse.data);
      setIsEditSuperMaintainerModalOpen(false);
      setEditingSuperMaintainer(null);
      toast.success('Ketua Rayon updated successfully');
    } catch (error) {
      toast.error('Failed to update Ketua Rayon');
    }
  };

  const handleResetPassword = async (superMaintainerId: number) => {
    const passwordError = InputValidators.validatePassword(newPassword);
    if (passwordError) {
      toast.error(passwordError);
      return;
    }
  
    try {
      await api.resetSuperMaintainerPassword(superMaintainerId, { new_password: newPassword });
      setIsResetPasswordModalOpen(false);
      setNewPassword('');
      toast.success('Password reset successfully');
    } catch (error) {
      toast.error('Failed to reset password');
    }
  };

  const handleInviteSuperMaintainer = async (superMaintainerId: number) => {
    try {
      await api.inviteSuperMaintainer(superMaintainerId);
      toast.success('Invitation sent successfully');
      // Refresh available super maintainers list
      const availableResponse = await api.getAvailableSuperMaintainers();
      setAvailableSuperMaintainers(availableResponse.data);
    } catch (error) {
      toast.error('Failed to send invitation');
    }
  };

  // Render functions
  const renderContent = () => {
    switch (activeTab) {
      case 'home':
        return (
          <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
            <div className="max-w-xl mx-auto space-y-6">
              {/* Enhanced Greeting Section */}
              <DashboardCard>
                <div className="flex items-center mb-4">
                  <div className="p-3 bg-[#FF4B1F] bg-opacity-10 rounded-full mr-4">
                    <Flame className="text-[#FF4B1F]" size={32} />
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold bg-gradient-to-r from-[#FF4B1F] to-[#FFB75E] bg-clip-text text-transparent">
                      Hello Leader, {profile?.name}!
                    </h1>
                    <p className="text-gray-600">
                      Welcome to Youth Fire Community
                    </p>
                  </div>
                </div>
              </DashboardCard>

              {/* Enhanced Bible Verse Section */}
              {bibleVerse && (
                <DashboardCard className="hover:bg-[#FFF9F5]">
                  <div className="flex items-start">
                    <div className="p-2 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
                      <Quote className="text-[#1E3799]" size={24} />
                    </div>
                    <div>
                      <p className="italic text-gray-700 mb-3 font-serif text-lg">
                        "{bibleVerse.text}"
                      </p>
                      <p className="text-[#1E3799] font-medium">
                        {bibleVerse.bookname} {bibleVerse.chapter}:{bibleVerse.verse}
                      </p>
                    </div>
                  </div>
                </DashboardCard>
              )}
              <BirthdaySlider birthdays={birthdays} />
            </div>
          </div>
        );

      case 'profile':
        return (
          <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full"> 
            <div className="max-w-xl mx-auto">
              {/* Enhanced Profile Header */}
              <DashboardCard className="mb-6">
                <div className="flex items-center">
                  <div className="p-4 bg-[#FF4B1F] bg-opacity-10 rounded-full mr-6">
                    <UserCircle2 className="text-[#FF4B1F]" size={48} />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold bg-gradient-to-r from-[#FF4B1F] to-[#FFB75E] bg-clip-text text-transparent">
                      {profile?.name}
                    </h2>
                    <p className="text-gray-600">Community Leader</p>
                  </div>
                </div>
              </DashboardCard>

              {/* Enhanced Profile Details */}
              <DashboardCard className="mb-6">
                <h3 className="text-xl font-semibold text-[#1E3799] mb-6 flex items-center">
                  <IdCard className="mr-2" size={24} />
                  Personal Information
                </h3>
                
                <div className="space-y-6">
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <Phone className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Phone Number</p>
                      <p className="text-gray-600">{profile?.phone_number}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <MapPin className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Address</p>
                      <p className="text-gray-600">{profile?.address}</p>
                    </div>
                  </div>

                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <Cake className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Birthday</p>
                      <p className="text-gray-600">{profile?.birthday}</p>
                    </div>
                  </div>
                </div>
              </DashboardCard>

              {/* Enhanced Logout Button */}
              <div className="flex justify-center">
                <button 
                  onClick={handleLogout}
                  className="bg-[#FF4B1F] text-white px-8 py-3 rounded-full hover:bg-opacity-90 
                    transition-all duration-300 transform hover:scale-105 flex items-center gap-2 shadow-lg"
                >
                  <LogOut size={20} />
                  <span className="font-medium">Logout</span>
                </button>
              </div>
            </div>
          </div>
        );
        case 'report':
            return (
              <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
                <div className="max-w-4xl mx-auto space-y-6">
                  <DashboardCard>
                    <div className="mb-6">
                      <h2 className="text-2xl font-bold bg-gradient-to-r from-[#FF4B1F] to-[#FFB75E] bg-clip-text text-transparent">
                        Community Activity Report
                      </h2>
                      <div className="flex gap-4 mt-4">
                        <div className="flex flex-col">
                          <label className="text-sm text-gray-600 mb-1">Start Date</label>
                          <input
                            type="date"
                            value={reportStartDate}
                            onChange={(e) => setReportStartDate(e.target.value)}
                            className="p-2 border rounded-lg focus:ring-2 focus:ring-[#FF4B1F]"
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="text-sm text-gray-600 mb-1">End Date</label>
                          <input
                            type="date"
                            value={reportEndDate}
                            onChange={(e) => setReportEndDate(e.target.value)}
                            className="p-2 border rounded-lg focus:ring-2 focus:ring-[#FF4B1F]"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="h-80">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={graphData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="count" name="Number of Sessions" />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </DashboardCard>
          
                  <div className="space-y-4">
                    {reportData.map((superMaintainer) => (
                      <DashboardCard key={superMaintainer.super_maintainer_id} className="hover:shadow-lg transition-all">
                        <div 
                          onClick={() => setExpandedReportSuperMaintainer(
                            expandedReportSuperMaintainer === superMaintainer.super_maintainer_id ? 
                            null : superMaintainer.super_maintainer_id
                          )}
                          className="cursor-pointer"
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-3">
                              <div className="p-2 bg-[#FF4B1F] bg-opacity-10 rounded-full">
                                <Shield className="text-[#FF4B1F]" size={24} />
                              </div>
                              <div>
                                <h3 className="font-semibold text-gray-800">
                                  {superMaintainer.super_maintainer_name}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {superMaintainer.maintainers_report.length} maintainers
                                </p>
                              </div>
                            </div>
                            {expandedReportSuperMaintainer === superMaintainer.super_maintainer_id ? 
                              <ChevronUp className="text-gray-500" /> : 
                              <ChevronDown className="text-gray-500" />
                            }
                          </div>
          
                          {expandedReportSuperMaintainer === superMaintainer.super_maintainer_id && (
                            <div className="mt-6 space-y-4">
                              {superMaintainer.maintainers_report.map((maintainer) => (
                                <div key={maintainer.maintainer_id} className="pl-4 border-l-2 border-[#FFB75E]">
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setExpandedReportMaintainer(
                                        expandedReportMaintainer === maintainer.maintainer_id ? 
                                        null : maintainer.maintainer_id
                                      );
                                    }}
                                    className="cursor-pointer"
                                  >
                                    <div className="flex items-center justify-between bg-gray-50 p-4 rounded-lg">
                                      <div className="flex items-center gap-2">
                                        <User className="text-[#FFB75E]" size={20} />
                                        <span className="font-medium">{maintainer.maintainer_name}</span>
                                      </div>
                                      {expandedReportMaintainer === maintainer.maintainer_id ? 
                                        <ChevronUp size={16} /> : 
                                        <ChevronDown size={16} />
                                      }
                                    </div>
          
                                    {expandedReportMaintainer === maintainer.maintainer_id && (
                                      <div className="mt-4 space-y-4">
                                        {maintainer.meetings.map((meeting) => (
                                          <div key={meeting.id} className="bg-white rounded-lg p-4 shadow-sm">
                                            <div className="flex justify-between items-start mb-3">
                                              <h4 className="font-medium text-[#FF4B1F]">{meeting.title}</h4>
                                              <span className={`px-3 py-1 rounded-full text-xs ${
                                                meeting.status === 'completed' ? 
                                                'bg-green-100 text-green-800' : 
                                                'bg-yellow-100 text-yellow-800'
                                              }`}>
                                                {meeting.status}
                                              </span>
                                            </div>
                                            <p className="text-gray-600 text-sm mb-3">{meeting.description}</p>
                                            <div className="flex gap-4 text-sm text-gray-500">
                                              <span className="flex items-center gap-1">
                                                <MapPin size={16} />
                                                {meeting.location}
                                              </span>
                                              <span className="flex items-center gap-1">
                                                <Clock size={16} />
                                                {new Date(meeting.meeting_datetime).toLocaleString()}
                                              </span>
                                            </div>
                                            <div className="mt-3 pt-3 border-t">
                                              <p className="text-sm font-medium text-gray-700 mb-2">Attendances:</p>
                                              {maintainer.absences
                                                .filter(absence => 
                                                  new Date(absence.recorded_at).toDateString() === 
                                                  new Date(meeting.meeting_datetime).toDateString()
                                                )
                                                .map((absence, idx) => (
                                                  <div key={idx} className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-md mb-2">
                                                    <div className="flex items-center gap-2">
                                                      <User size={14} className="text-gray-500" />
                                                      <span className="text-sm text-gray-700">
                                                        {userDetails[absence.user_id] || 'Loading...'}
                                                      </span>
                                                    </div>
                                                    <span className="text-xs text-gray-500">
                                                      {new Date(absence.recorded_at).toLocaleTimeString()}
                                                    </span>
                                                  </div>
                                                ))
                                              }
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </DashboardCard>
                    ))}
                  </div>
                </div>
              </div>
            );
        case 'super-maintainers':
          return (
            <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full"> 
              <div className="max-w-xl mx-auto">
                {/* Enhanced Header with Updated Button Layout */}
                <DashboardCard className="mb-6">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="p-3 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
                        <Shield className="text-[#1E3799]" size={32} />
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                          Ketua Rayon
                        </h2>
                        <p className="text-gray-600">
                          Managing {superMaintainers.length} community leaders
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => setIsAddSuperMaintainerModalOpen(true)}
                      className="p-3 bg-[#1E3799] text-white rounded-full
                        hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                    >
                      <UserPlus size={18} />
                    </button>
                  </div>
                </DashboardCard>
                
              {/* Super Maintainers List */}
              {superMaintainers.length === 0 ? (
                <DashboardCard className="text-center py-12">
                  <Users className="text-gray-300 mx-auto mb-4" size={64} />
                  <p className="text-gray-500">No ketua rayon available</p>
                </DashboardCard>
              ) : (
                <div className="space-y-4">
                  {superMaintainers.map(superMaintainer => (
                    <DashboardCard key={superMaintainer.id}>
                      <div
                        onClick={() => setExpandedSuperMaintainerId(
                          expandedSuperMaintainerId === superMaintainer.id ? null : superMaintainer.id
                        )}
                        className="flex justify-between items-center cursor-pointer"
                      >
                        <div className="flex items-center">
                          <div className="p-2 bg-[#1E3799] bg-opacity-10 rounded-full mr-3">
                            <Shield className="text-[#1E3799]" size={20} />
                          </div>
                          <div>
                            <h3 className="font-semibold text-gray-800">{superMaintainer.name}</h3>
                            <p className="text-sm text-gray-500">{superMaintainer.phone_number}</p>
                          </div>
                        </div>
                        {expandedSuperMaintainerId === superMaintainer.id ? (
                          <ChevronUp className="text-gray-500" size={20} />
                        ) : (
                          <ChevronDown className="text-gray-500" size={20} />
                        )}
                      </div>

                      {/* Expanded Content */}
                      {expandedSuperMaintainerId === superMaintainer.id && (
                        <div className="mt-4 pt-4 border-t border-gray-100">
                          <div className="space-y-4">
                            <div className="p-3 bg-[#F5F6FA] rounded-lg">
                              <p className="text-sm font-medium text-gray-600">Email</p>
                              <p className="text-gray-800">{superMaintainer.email}</p>
                            </div>

                            {/* Maintainers List */}
                            <div className="mt-4">
                              <h4 className="font-medium text-[#1E3799] mb-3">Ketua MK:</h4>
                              {superMaintainer.maintainers.map(maintainer => (
                                <div key={maintainer.id} className="bg-[#F5F6FA] rounded-lg p-4 mb-2">
                                  <div className="flex justify-between items-center">
                                    <div>
                                      <p className="font-medium text-gray-800">{maintainer.name}</p>
                                      <p className="text-sm text-gray-500">{maintainer.phone_number}</p>
                                    </div>
                                    <button
                                      onClick={() => setExpandedMaintainerId(
                                        expandedMaintainerId === maintainer.id ? null : maintainer.id
                                      )}
                                      className="text-[#1E3799]"
                                    >
                                      {expandedMaintainerId === maintainer.id ? (
                                        <ChevronUp size={20} />
                                      ) : (
                                        <ChevronDown size={20} />
                                      )}
                                    </button>
                                  </div>

                                  {expandedMaintainerId === maintainer.id && (
                                    <div className="mt-4 pl-4 border-l-2 border-[#8E44AD]">
                                      <p className="text-sm font-medium text-[#8E44AD] mb-2">MK Members:</p>
                                      {maintainer.users.map(user => (
                                        <div key={user.id} className="p-3 bg-white rounded-lg mb-2 hover:shadow-md transition-all">
                                          <p className="font-medium text-gray-800">{user.name}</p>
                                          <p className="text-sm text-gray-500">{user.phone_number}</p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>

                            {/* Action Buttons */}
                            <div className="flex gap-3 mt-4">
                            <button
                              onClick={() => {
                                setEditingSuperMaintainer(superMaintainer);
                                setIsEditSuperMaintainerModalOpen(true);
                              }}
                              className="p-2 bg-[#1E3799] text-white rounded-full hover:bg-opacity-90 transition-all"
                            >
                              <Edit2 size={15} />
                            </button>
                            <button
                              onClick={() => {
                                setEditingSuperMaintainer(superMaintainer);
                                setIsResetPasswordModalOpen(true);
                              }}
                              className="p-2 bg-[#FFB75E] text-white rounded-full hover:bg-opacity-90 transition-all"
                            >
                              <Key size={16} />
                            </button>
                            <button
                              onClick={() => {
                                setRemovingId(superMaintainer.id);
                                setShowConfirm(true);
                              }}
                              className="p-2 bg-[#FF4B1F] text-white rounded-full hover:bg-opacity-90 transition-all"
                            >
                              <Trash2 size={16} />
                          </button>
                          </div>
                        </div>
                      </div>
                    )}
                    </DashboardCard>
                  ))}
                </div>
              )}
            </div>
          </div>
        );

      case 'ketua-rayon':
        return (
          <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
            <div className="max-w-xl mx-auto">
              {/* Enhanced Header */}
              <DashboardCard className="mb-6">
                <div className="flex items-center">
                  <div className="p-3 bg-[#8E44AD] bg-opacity-10 rounded-full mr-4">
                    <Building className="text-[#8E44AD]" size={32} />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold bg-gradient-to-r from-[#8E44AD] to-[#2ECC71] bg-clip-text text-transparent">
                      Available Ketua Rayon
                    </h2>
                    <p className="text-gray-600">
                      Find and invite new community leaders
                    </p>
                  </div>
                </div>
              </DashboardCard>

              {/* Available Leaders List */}
              {availableSuperMaintainers.length === 0 ? (
                <DashboardCard className="text-center py-12">
                  <Users className="text-gray-300 mx-auto mb-4" size={64} />
                  <p className="text-gray-500">No available leaders at the moment</p>
                  <p className="text-gray-400 text-sm mt-2">Check back later</p>
                </DashboardCard>
              ) : (
                <div className="space-y-4">
                  {availableSuperMaintainers.map(maintainer => (
                    <DashboardCard key={maintainer.id} className="hover:border-[#8E44AD]">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <div className="p-2 bg-[#8E44AD] bg-opacity-10 rounded-full mr-3">
                            <Shield className="text-[#8E44AD]" size={24} />
                          </div>
                          <div>
                            <h3 className="font-semibold text-gray-800">{maintainer.name}</h3>
                            <div className="flex items-center text-sm text-gray-500 mt-1">
                              <Phone className="w-4 h-4 mr-1" />
                              {maintainer.phone_number}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center mt-4">
                          <button
                            onClick={() => handleInviteSuperMaintainer(maintainer.id)}
                            className="p-3 bg-[#2ECC71] text-white rounded-full hover:bg-opacity-90 
                              transition-all duration-300 transform hover:scale-105 shadow-md"
                          >
                            <UserPlus size={18} />
                          </button>
                        </div>
                      </div>
                    </DashboardCard>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col h-screen bg-[#FFF9F5]">
      <Toaster position="top-right" />
      
      {/* Main Content Area - Now First */}
      <div className="flex-grow overflow-y-auto">
        {renderContent()}
      </div>
      
      {/* Modals */}
      {isResetPasswordModalOpen && editingSuperMaintainer && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#FFB75E]">
          <h2 className="text-xl font-bold mb-6 text-[#FFB75E]">Reset Password</h2>
          <div className="space-y-4">
            <p className="text-gray-600">Reset password for {editingSuperMaintainer.name}</p>
            <input 
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#FFB75E] focus:border-transparent"
            />
          </div>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => {
                setIsResetPasswordModalOpen(false);
                setNewPassword('');
              }}
              className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
            >
              Cancel
            </button>
            <button
              onClick={() => handleResetPassword(editingSuperMaintainer.id)}
              className="px-6 py-2 bg-[#FFB75E] text-white rounded-full hover:bg-opacity-90 transition-all"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    )}
    {showConfirm && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#FF4B1F]">
          <div className="text-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
              <Trash2 className="h-6 w-6 text-[#FF4B1F]" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">Confirm Removal</h3>
            <p className="text-sm text-gray-500">
              Are you sure you want to remove this leader? This action cannot be undone.
            </p>
          </div>
          <div className="flex justify-center gap-3 mt-6">
            <button
              onClick={() => setShowConfirm(false)}
              className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                try {
                  await api.removeSuperMaintainer(removingId!);
                  const superMaintainersResponse = await api.getMySuperMaintainers();
                  setSuperMaintainers(superMaintainersResponse.data);
                  setShowConfirm(false);
                  toast.success('Leader removed successfully');
                } catch (error) {
                  toast.error('Failed to remove leader');
                }
              }}
              className="px-6 py-2 bg-[#FF4B1F] text-white rounded-full hover:bg-opacity-90 transition-all"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    )}
    {isAddSuperMaintainerModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#1E3799]">
          <h2 className="text-xl font-bold mb-6 text-[#1E3799]">Add New Ketua Rayon</h2>
          <div className="space-y-4">
            <input 
              type="text"
              placeholder="Name"
              value={newSuperMaintainer.name}
              onChange={(e) => setNewSuperMaintainer({ ...newSuperMaintainer, name: e.target.value })}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
            />
            <input 
              type="text"
              placeholder="Username"
              value={newSuperMaintainer.username}
              onChange={(e) => setNewSuperMaintainer({ ...newSuperMaintainer, username: e.target.value })}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
            />
            <input 
              type="text"
              placeholder="Phone Number"
              value={newSuperMaintainer.phone_number}
              onChange={(e) => setNewSuperMaintainer({ ...newSuperMaintainer, phone_number: e.target.value })}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
            />
            <input 
              type="email"
              placeholder="Email"
              value={newSuperMaintainer.email}
              onChange={(e) => setNewSuperMaintainer({ ...newSuperMaintainer, email: e.target.value })}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
            />
            <input 
              type="date"
              placeholder="Birthday"
              value={newSuperMaintainer.birthday}
              onChange={(e) => setNewSuperMaintainer({ ...newSuperMaintainer, birthday: e.target.value })}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
            />
            <input 
              type="password"
              placeholder="Password"
              value={newSuperMaintainer.password}
              onChange={(e) => setNewSuperMaintainer({ ...newSuperMaintainer, password: e.target.value })}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
            />
          </div>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setIsAddSuperMaintainerModalOpen(false)}
              className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
            >
              Cancel
            </button>
            <button
              onClick={handleAddSuperMaintainer}
              className="px-6 py-2 bg-[#1E3799] text-white rounded-full hover:bg-opacity-90 transition-all"
            >
              Add Leader
            </button>
          </div>
        </div>
      </div>
    )}
      {isEditSuperMaintainerModalOpen && editingSuperMaintainer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#1E3799]">
            <h2 className="text-xl font-bold mb-6 text-[#1E3799]">Edit Leader Profile</h2>
            <div className="space-y-4">
              <input 
                type="text"
                placeholder="Name"
                value={editingSuperMaintainer.name}
                onChange={(e) => setEditingSuperMaintainer({ 
                  ...editingSuperMaintainer, 
                  name: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="text"
                placeholder="Phone Number"
                value={editingSuperMaintainer.phone_number}
                onChange={(e) => setEditingSuperMaintainer({ 
                  ...editingSuperMaintainer, 
                  phone_number: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="email"
                placeholder="Email"
                value={editingSuperMaintainer.email}
                onChange={(e) => setEditingSuperMaintainer({ 
                  ...editingSuperMaintainer, 
                  email: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="date"
                placeholder="Birthday"
                value={editingSuperMaintainer.birthday}
                onChange={(e) => setEditingSuperMaintainer({ 
                  ...editingSuperMaintainer, 
                  birthday: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => setIsEditSuperMaintainerModalOpen(false)}
                className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateSuperMaintainer}
                className="px-6 py-2 bg-[#1E3799] text-white rounded-full hover:bg-opacity-90 transition-all"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Enhanced Navigation - Now at Bottom */}
      <nav className="bg-white border-t border-gray-100 py-3 px-4 flex justify-around fixed bottom-0 left-0 right-0 z-10">
        {[
          { id: 'home', icon: Home, label: 'Home' },
          { id: 'profile', icon: User, label: 'Profile' },
          { id: 'report', icon: BarChart2, label: 'Report' },
          { id: 'super-maintainers', icon: ShieldIcon, label: 'Ketua Rayon' },
          { id: 'ketua-rayon', icon: Building, label: 'List Rayon' }
        ].map(item => (
          <button 
            key={item.id}
            onClick={() => setActiveTab(item.id)} 
            className={`flex flex-col items-center transition-all duration-300 ${
              activeTab === item.id 
                ? 'text-[#FF4B1F] transform scale-110' 
                : 'text-gray-400 hover:text-[#FF4B1F]'
            }`}
          >
            <item.icon size={20} />
            <span className="text-xs mt-1 font-medium">{item.label}</span>
          </button>
        ))}
      </nav>

      {/* Add bottom padding to content to prevent overlap with fixed navbar */}
      <div className="h-16"></div>
    </div>
  );
};

export default AdminDashboard;