import React from 'react';

interface VideoBackgroundProps {
  videoSrc: string;
  children: React.ReactNode;
}

export const VideoBackground: React.FC<VideoBackgroundProps> = ({ videoSrc, children }) => {
  return (
    <div className="relative h-screen overflow-hidden">
      <video 
        autoPlay 
        loop 
        muted 
        className="absolute z-0 w-full h-full object-cover"
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default VideoBackground;