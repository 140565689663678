import React, { useState } from 'react';
import { api } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { VideoBackground } from '../components/VideoBackground';
import { toast, Toaster } from 'react-hot-toast';
import { Phone, Lock } from 'lucide-react';
import { InputValidators } from '../utils/validationUtils';

export const LoginPage: React.FC = () => {
  const [credentials, setCredentials] = useState({
    phone_number: '',
    password: ''
  });
  const navigate = useNavigate();

  const validateForm = () => {
    // Phone validation
    const phoneError = InputValidators.validatePhone(credentials.phone_number);
    if (phoneError) {
      toast.error(phoneError);
      return false;
    }
  
    // Password validation
    const passwordError = InputValidators.validatePassword(credentials.password);
    if (passwordError) {
      toast.error(passwordError);
      return false;
    }
  
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await api.login({
        username: credentials.phone_number,
        password: credentials.password
      });
      
      localStorage.setItem('access_token', response.data.access_token);
      
      const profileResponse = await api.getUserProfile();
      const user = profileResponse.data;

      // Enhanced authentication with role-specific handling
      switch(user.role) {
        case 'user':
          toast.success(`Welcome, ${user.name}!`, { 
            icon: '👋',
            duration: 3000,
            position: 'top-center'
          });
          navigate('/dashboard/user');
          break;
        case 'maintainer':
          toast.success(`Welcome, Ketua MK ${user.name}!`, { 
            icon: '🛠️',
            duration: 3000,
            position: 'top-center'
          });
          navigate('/dashboard/maintainer');
          break;
        case 'super_maintainer':
          toast.success(`Welcome, Ketua Rayon ${user.name}!`, { 
            icon: '⭐',
            duration: 3000,
            position: 'top-center'
          });
          navigate('/dashboard/super-maintainer');
          break;
        case 'admin':
          toast.success(`Welcome, Leader ${user.name}!`, { 
            icon: '🔐',
            duration: 3000,
            position: 'top-center'
          });
          navigate('/dashboard/admin');
          break;
        case 'service':
          toast.success(`Welcome, Service Staff ${user.name}!`, { 
            icon: '🔧',
            duration: 3000,
            position: 'top-center'
          });
          navigate('/dashboard/service');
          break;
        default:
          toast.error('Unauthorized access', {
            duration: 3000,
            position: 'top-center'
          });
          navigate('/');
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.detail || 
                         error.response?.data?.message || 
                         'Login failed. Please check your credentials.';
      
      toast.error(errorMessage, {
        duration: 3000,
        position: 'top-center'
      });
    }
  };

  return (
    <VideoBackground videoSrc="/video.mp4">
      <Toaster 
        toastOptions={{
          success: { 
            style: { 
              background: '#4CAF50', 
              color: 'white' 
            } 
          },
          error: { 
            style: { 
              background: '#F44336', 
              color: 'white' 
            } 
          }
        }} 
      />
      <div className="h-screen flex items-center justify-center">
        <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-xl shadow-2xl border border-gray-200">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-2">Welcome Back 👋</h2>
            <p className="text-gray-500">Sign in to continue</p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Phone className="text-gray-400" size={20} />
              </div>
              <input 
                type="tel" 
                placeholder="Phone Number (08/+62)"
                value={credentials.phone_number}
                onChange={(e) => setCredentials({...credentials, phone_number: e.target.value})}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required 
              />
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="text-gray-400" size={20} />
              </div>
              <input 
                type="password" 
                placeholder="Password"
                value={credentials.password}
                onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-900 transition"
                required 
              />
            </div>
            <button 
              type="submit" 
              className="w-full bg-red-900 text-white p-3 rounded-lg hover:bg-red-800 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
            >
              Login
            </button>
          </form>
          <div className="text-center">
            <p className="text-gray-600">
              Don't have an account? 
              <span 
                onClick={() => navigate('/register')}
                className="text-red-900 ml-1 cursor-pointer hover:underline"
              >
                Register here
              </span>
            </p>
          </div>
        </div>
      </div>
    </VideoBackground>
  );
};

export default LoginPage;