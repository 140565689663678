import React from 'react';

const EnhancedLoadingScreen = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#FFFAF6] to-[#FFE4D6] flex flex-col items-center justify-center p-4 relative overflow-hidden">
      {/* Floating background elements */}
      {[...Array(6)].map((_, i) => (
        <div
          key={i}
          className="absolute rounded-full opacity-20 animate-float"
          style={{
            width: `${Math.random() * 100 + 50}px`,
            height: `${Math.random() * 100 + 50}px`,
            background: `rgba(255, 107, 53, ${Math.random() * 0.3 + 0.1})`,
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 2}s`,
            animationDuration: `${Math.random() * 3 + 2}s`
          }}
        />
      ))}

      {/* Main content container */}
      <div className="relative z-10 flex flex-col items-center">
        {/* Animated logo */}
        <div className="relative mb-12">
          {/* Outer rotating ring */}
          <div className="absolute inset-0 w-32 h-32 rounded-full border-4 border-dashed border-[#FF6B35] animate-spin-slow opacity-20" />
          
          {/* Main logo container */}
          <div className="relative w-24 h-24">
            {/* Pulsing circles */}
            {[0, 1, 2].map((index) => (
              <div
                key={index}
                className="absolute inset-0 w-full h-full rounded-full border-2 border-[#FF6B35] animate-ping"
                style={{
                  animationDelay: `${index * 0.5}s`,
                  opacity: 0.2 - index * 0.05
                }}
              />
            ))}
            
            {/* Center logo */}
            <div className="w-full h-full bg-gradient-to-br from-[#FF6B35] to-[#FF8B35] rounded-full shadow-lg flex items-center justify-center animate-pulse">
              <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center shadow-inner">
                <div className="w-12 h-12 bg-gradient-to-br from-[#FF6B35] to-[#FF8B35] rounded-full" />
              </div>
            </div>
          </div>
        </div>

        {/* Text content */}
        <div className="text-center mb-8 relative">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-[#2D3142] to-[#FF6B35] bg-clip-text text-transparent mb-3">
            Youth Fire MK
          </h2>
          <p className="text-[#666666] text-lg relative inline-block">
            Loading your dashboard
            <span className="loading-dots">...</span>
          </p>
        </div>

        {/* Progress bar */}
        <div className="w-72 relative">
          <div className="h-1.5 bg-gray-200/50 rounded-full overflow-hidden backdrop-blur-sm">
            <div className="h-full w-full bg-gradient-to-r from-[#FF6B35] to-[#FF8B35] rounded-full animate-loading-progress" />
          </div>
          {/* Glowing effect */}
          <div className="absolute inset-0 h-1.5 bg-gradient-to-r from-[#FF6B35] to-[#FF8B35] rounded-full blur-md opacity-30 animate-loading-glow" />
        </div>

        {/* Loading status indicators */}
        <div className="mt-8 flex gap-4">
          {['Loading', 'Preparing', 'Almost ready'].map((text, index) => (
            <div
              key={text}
              className={`px-4 py-1.5 rounded-full text-sm transition-all duration-500 ${
                index === Math.floor(Date.now() / 2000) % 3
                  ? 'bg-[#FF6B35] text-white shadow-lg scale-110'
                  : 'bg-white/80 text-[#666666] shadow-sm scale-100'
              }`}
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Add this to your CSS or tailwind.config.js
const style = document.createElement('style');
style.textContent = `
  @keyframes loading-progress {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  
  @keyframes loading-glow {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 0.6; }
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0) rotate(0deg); }
    50% { transform: translateY(-20px) rotate(10deg); }
  }
  
  @keyframes dots {
    0%, 20% { content: '.'; }
    40%, 60% { content: '..'; }
    80%, 100% { content: '...'; }
  }
  
  .animate-loading-progress {
    animation: loading-progress 2s infinite;
  }
  
  .animate-loading-glow {
    animation: loading-glow 2s infinite;
  }
  
  .animate-float {
    animation: float 3s infinite;
  }
  
  .animate-spin-slow {
    animation: spin 8s linear infinite;
  }
  
  .loading-dots::after {
    content: '';
    animation: dots 1.5s infinite;
  }
`;
document.head.appendChild(style);

export default EnhancedLoadingScreen;