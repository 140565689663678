import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoBackground } from '../components/VideoBackground';

export const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const [hoveredButton, setHoveredButton] = useState<'register' | 'login' | null>(null);

  const buttonStyle = (type: 'register' | 'login') => {
    const baseStyle = "relative overflow-hidden text-black px-6 py-3 rounded-full w-full max-w-xs transition-all duration-300 ease-in-out transform ";
    
    const hoverEffects = {
      register: hoveredButton === 'register' 
        ? "scale-105 shadow-lg bg-[#8B0000] text-white" 
        : "bg-white text-black hover:bg-[#8B0000] hover:text-white",
      login: hoveredButton === 'login' 
        ? "scale-105 shadow-lg bg-[#8B0000] text-white" 
        : "bg-white text-black hover:bg-[#8B0000] hover:text-white"
    };

    return `${baseStyle} ${hoverEffects[type]} relative group`;
  };

  return (
    <VideoBackground videoSrc="/video.mp4">
      <div 
        className="flex flex-col items-center justify-end h-screen p-8 space-y-4"
        style={{
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.7))'
        }}
      >

        <button 
          onClick={() => navigate('/register')}
          onMouseEnter={() => setHoveredButton('register')}
          onMouseLeave={() => setHoveredButton(null)}
          className={buttonStyle('register')}
        >
          <span className="relative z-10">Join YouthFire!</span>
        </button>

        <button 
          onClick={() => navigate('/login')}
          onMouseEnter={() => setHoveredButton('login')}
          onMouseLeave={() => setHoveredButton(null)}
          className={buttonStyle('login')}
        >
          <span className="relative z-10">Login</span>
        </button>
      </div>
    </VideoBackground>
  );
};

export default LandingPage;