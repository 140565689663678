import React, { useState, useEffect } from 'react';
import { 
  Home,
  User,
  BookOpen,
  Calendar,
  Clock,
  LogOut,
  Building,
  UserCircle,
  Mail,
  UserCircle2,
  Phone,
  MapPin,
  Shield,
  Star,
  Award,
  Quote,
  FileText,
  BookmarkIcon,
  ChevronUp,
  ChevronDown,
  CheckCircle,
  Cake
} from 'lucide-react';
import { api } from '../../services/api';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BirthdaySlider from '../../components/BirthdaySlider';
import { InputValidators } from '../../utils/validationUtils';
import EnhancedLoadingScreen from '../../components/EnhancedLoadingScreen';

// Interfaces
export interface Birthday {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string | null;
  maintainer?: {
    name: string;
  };
  super_maintainer?: {
    name: string;
  };
  admin?: {
    name: string;
  };
  role: string;
  service_info: any | null;
}

interface UserProfile {
  id: number;
  name: string;
  phone_number: string;
  email: string;
  address: string;
  birthday: string;
  maintainer?: {
    name: string;
    phone_number: string;
    birthday: string;
  };
  super_maintainer?: {
    name: string;
    phone_number: string;
    birthday: string;
  };
  admin?: {
    name: string;
    phone_number: string;
    birthday: string;
  };
  role: string;
}

interface BibleVerse {
  bookname: string;
  chapter: string;
  verse: string;
  text: string;
}

interface Invitation {
  id: number;
  maintainer_name: string;
  details: string;
  created_at: string;
}

interface SermonNote {
  id: number;
  title: string;
  description: string;
  timestamp: string;
  maintainer_id: number;
}

interface MeetingRoomInfo {
  title: string;
  description: string;
  location: string;
  meeting_datetime: string;
  id: number;
  maintainer_id: number;
  status: string;
  response: string;
  created_at: string;
  expires_at: string;
}

const UserDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [bibleVerse, setBibleVerse] = useState<BibleVerse | null>(null);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [sermonNotes, setSermonNotes] = useState<SermonNote[]>([]);
  const [meetingRoomInfo, setMeetingRoomInfo] = useState<MeetingRoomInfo[]>([]);
  const [expandedNoteId, setExpandedNoteId] = useState<number | null>(null);
  const [absenceCode, setAbsenceCode] = useState('');
  const [isAbsenceModalOpen, setIsAbsenceModalOpen] = useState(false);
  const [birthdays, setBirthdays] = useState<Birthday[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        const response = await api.getTodaysBirthdays();
        setBirthdays(response.data);
      } catch (error) {
        console.error('Failed to load birthdays:', error);
        toast.error('Failed to load birthdays');
      }
    };
    fetchBirthdays();
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const profileResponse = await api.getUserProfile();
        const profileData = profileResponse.data;
        setUserProfile(profileData);

        const verseResponse = await axios.get(
          'https://labs.bible.org/api/?passage=random&type=json&callback=myCallback',
          {
            transformResponse: [(data) => {
              const jsonMatch = data.match(/myCallback\((.*)\)/);
              return jsonMatch && jsonMatch[1] ? JSON.parse(jsonMatch[1]) : data;
            }]
          }
        );
        
        if (Array.isArray(verseResponse.data) && verseResponse.data.length > 0) {
          const verse = verseResponse.data[0];
          setBibleVerse({
            bookname: verse.bookname || '',
            chapter: verse.chapter || '',
            verse: verse.verse || '',
            text: verse.text || ''
          });
        }

        if (profileData.maintainer) {
          const [meetingRoomResponse, sermonNotesResponse] = await Promise.all([
            api.getMeetingRoomInfo(),
            api.getSermonNotes()
          ]);
          setMeetingRoomInfo(meetingRoomResponse.data);
          setSermonNotes(sermonNotesResponse.data);
          setIsLoading(false);
        } else {
          const invitationsResponse = await api.getUserInvitations();
          setInvitations(invitationsResponse.data);
          setIsLoading(false);
        }
      } catch (error) {
        toast.error('Failed to load dashboard data');
      }
    };

    fetchInitialData();
  }, []);

  if (isLoading) {
    return <EnhancedLoadingScreen />;
  }

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'approved':
        return {
          bg: 'bg-green-50',
          text: 'text-green-700',
          icon: '✓',
          border: 'border-green-200'
        };
      case 'pending':
        return {
          bg: 'bg-yellow-50',
          text: 'text-yellow-700',
          icon: '⌛',
          border: 'border-yellow-200'
        };
      default:
        return {
          bg: 'bg-red-50',
          text: 'text-red-700',
          icon: '✕',
          border: 'border-red-200'
        };
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
    toast.success('Logged out successfully', {
      icon: '👋',
      duration: 3000
    });
  };

  const handleInvitationResponse = async (invitationId: number, accepted: boolean) => {
    try {
      await api.respondToInvitation(invitationId, accepted);
      toast.success(accepted ? 'Invitation Accepted' : 'Invitation Declined', {
        icon: accepted ? '✅' : '❌',
      });
      setInvitations(prev => prev.filter(inv => inv.id !== invitationId));
    } catch (error) {
      toast.error('Failed to respond to invitation');
    }
  };

  const handleRecordAbsence = async () => {
    const validationError = InputValidators.validateAbsenceToken(absenceCode);
    if (validationError) {
      toast.error(validationError);
      return;
    }

    try {
      await api.recordAbsence(absenceCode);
      toast.success('Absence recorded successfully');
      setIsAbsenceModalOpen(false);
      setAbsenceCode('');
    } catch (error) {
      toast.error('Failed to record absence');
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'home':
        return (
          <div className="space-y-6">
            {/* Welcome Card */}
            <div className="bg-white rounded-xl shadow-lg p-6 border-l-4 border-[#FF6B35]">
              <div className="flex items-center">
                <div className="bg-[#FFF3E6] p-3 rounded-full mr-4">
                  <Home className="text-[#FF6B35]" size={32} />
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-[#2D3142]">
                    Welcome to Youth Fire MK
                  </h1>
                  <p className="text-[#666666]">
                    {userProfile?.name}
                  </p>
                </div>
              </div>
            </div>

            {/* Bible Verse Card */}
            {bibleVerse && (
              <div className="bg-[#004E89] text-white rounded-xl shadow-lg p-6">
                <div className="flex items-start">
                  <Quote className="text-[#FFB563] mr-4 mt-1" size={32} />
                  <div>
                    <p className="italic mb-3 text-lg">"{bibleVerse.text}"</p>
                    <p className="text-[#FFB563]">
                      {bibleVerse.bookname} {bibleVerse.chapter}:{bibleVerse.verse}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <BirthdaySlider birthdays={birthdays} />
          </div>
        );

      case 'profile':
        return (
          <div className="space-y-6">
            {/* Profile Header */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center mb-6">
                <div className="bg-[#FFF3E6] p-3 rounded-full mr-4">
                  <UserCircle2 className="text-[#FF6B35]" size={40} />
                </div>
                <div>
                  <h2 className="text-2xl font-bold text-[#2D3142]">
                    {userProfile?.name}
                  </h2>
                  <p className="text-[#666666]">{userProfile?.role || 'Member'}</p>
                </div>
              </div>

              {/* Profile Details */}
              <div className="space-y-4">
                <div className="bg-[#FFFAF6] p-4 rounded-lg">
                  <div className="flex items-center">
                    <Phone className="text-[#FF6B35] mr-3" size={20} />
                    <div>
                      <p className="font-medium text-[#2D3142]">Phone</p>
                      <p className="text-[#666666]">{userProfile?.phone_number}</p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#FFFAF6] p-4 rounded-lg">
                  <div className="flex items-center">
                    <Mail className="text-[#FF6B35] mr-3" size={20} />
                    <div>
                      <p className="font-medium text-[#2D3142]">Email</p>
                      <p className="text-[#666666]">{userProfile?.email}</p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#FFFAF6] p-4 rounded-lg">
                  <div className="flex items-center">
                    <Cake className="text-[#FF6B35] mr-3" size={20} />
                    <div>
                      <p className="font-medium text-[#2D3142]">Birthday</p>
                      <p className="text-[#666666]">{userProfile?.birthday}</p>
                    </div>
                  </div>
                </div>
                <div className="bg-[#FFFAF6] p-4 rounded-lg">
                  <div className="flex items-center">
                    <MapPin className="text-[#FF6B35] mr-3" size={20} />
                    <div>
                      <p className="font-medium text-[#2D3142]">Address</p>
                      <p className="text-[#666666]">{userProfile?.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Leadership Connections */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <h3 className="text-xl font-bold text-[#2D3142] mb-4">
                Leadership Connections
              </h3>
              <div className="space-y-4">
                {userProfile?.maintainer && (
                  <div className="bg-[#FFFAF6] p-4 rounded-lg">
                    <div className="flex items-center">
                      <Star className="text-[#FFB563] mr-3" size={20} />
                      <div>
                        <p className="font-medium text-[#2D3142]">Ketua MK</p>
                        <p className="text-[#666666]">{userProfile.maintainer.name}</p>
                        <p className="text-sm text-[#666666]">{userProfile.maintainer.phone_number}</p>
                      </div>
                    </div>
                  </div>
                )}
                {userProfile?.super_maintainer && (
                  <div className="bg-[#FFFAF6] p-4 rounded-lg">
                    <div className="flex items-center">
                      <Award className="text-[#FFB563] mr-3" size={20} />
                      <div>
                        <p className="font-medium text-[#2D3142]">Ketua Rayon</p>
                        <p className="text-[#666666]">{userProfile.super_maintainer.name}</p>
                        <p className="text-sm text-[#666666]">{userProfile.super_maintainer.phone_number}</p>
                      </div>
                    </div>
                  </div>
                )}
                {userProfile?.admin && (
                  <div className="bg-[#FFFAF6] p-4 rounded-lg">
                    <div className="flex items-center">
                      <Shield className="text-[#FFB563] mr-3" size={20} />
                      <div>
                        <p className="font-medium text-[#2D3142]">Leader</p>
                        <p className="text-[#666666]">{userProfile.admin.name}</p>
                        <p className="text-sm text-[#666666]">{userProfile.admin.phone_number}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <button 
              onClick={handleLogout}
              className="w-full bg-[#FF5252] text-white py-3 rounded-xl hover:bg-red-600 transition flex items-center justify-center gap-2"
            >
              <LogOut size={20} />
              <span>Logout</span>
            </button>
          </div>
        );

      case 'mk-info':
        return (
          <div className="space-y-6">
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex items-center mb-6">
                <div className="bg-[#FFF3E6] p-3 rounded-full mr-4">
                  <Building className="text-[#FF6B35]" size={32} />
                </div>
                <div>
                  <h2 className="text-2xl font-bold text-[#2D3142]">MK Location Details</h2>
                  <p className="text-[#666666]">
                    {meetingRoomInfo.length} active location{meetingRoomInfo.length !== 1 ? 's' : ''}
                  </p>
                </div>
              </div>

              {meetingRoomInfo.length === 0 ? (
                <div className="flex flex-col items-center justify-center p-8">
                  <div className="bg-[#FFF3E6] p-6 rounded-full mb-4">
                    <MapPin className="text-[#FF6B35]" size={64} />
                  </div>
                  <p className="text-xl font-semibold text-[#2D3142]">No Locations Available</p>
                  <p className="text-[#666666] text-sm mt-2">Check back later for updates</p>
                </div>
              ) : (
                <div className="space-y-6">
                  {meetingRoomInfo.map(info => {
                    const statusStyle = getStatusColor(info.status);
                    return (
                      <div 
                        key={info.id}
                        className="bg-[#FFFAF6] rounded-xl overflow-hidden shadow-sm border border-[#FFE4D6]"
                      >
                        {/* Header Section */}
                        <div className="p-4 border-b border-[#FFE4D6]">
                          <div className="flex justify-between items-start">
                            <div className="flex-1">
                              <h3 className="text-lg font-semibold text-[#2D3142] mb-1">
                                {info.title}
                              </h3>
                              <p className="text-sm text-[#666666]">
                                {new Date(info.meeting_datetime).toLocaleDateString('en-US', {
                                  weekday: 'long',
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric'
                                })}
                              </p>
                            </div>
                            <span className={`
                              ${statusStyle.bg} ${statusStyle.text}
                              px-4 py-1 rounded-full text-sm font-medium
                              flex items-center gap-1 border ${statusStyle.border}
                            `}>
                              {statusStyle.icon} {info.status}
                            </span>
                          </div>
                        </div>

                        {/* Content Section */}
                        <div className="p-4 space-y-4">
                          {/* Time Card */}
                          <div className="flex items-center p-3 bg-white rounded-lg border border-[#FFE4D6]">
                            <Clock className="text-[#FF6B35] mr-3" size={20} />
                            <div>
                              <p className="text-sm font-medium text-[#2D3142]">MK Time</p>
                              <p className="text-sm text-[#666666]">
                                {new Date(info.meeting_datetime).toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true
                                })}
                              </p>
                            </div>
                          </div>

                          {/* Location Card */}
                          <div className="flex items-center p-3 bg-white rounded-lg border border-[#FFE4D6]">
                            <MapPin className="text-[#FF6B35] mr-3" size={20} />
                            <div>
                              <p className="text-sm font-medium text-[#2D3142]">Location</p>
                              <p className="text-sm text-[#666666]">{info.location}</p>
                            </div>
                          </div>

                          {/* Description Card */}
                          <div className="p-3 bg-white rounded-lg border border-[#FFE4D6]">
                            <p className="text-sm font-medium text-[#2D3142] mb-1">Description</p>
                            <p className="text-sm text-[#666666] leading-relaxed">
                              {info.description}
                            </p>
                          </div>

                          {/* Response Card (if available) */}
                          {info.response && (
                            <div className="p-3 bg-white rounded-lg border border-[#FFE4D6]">
                              <p className="text-sm font-medium text-[#2D3142] mb-1">Response</p>
                              <p className="text-sm text-[#666666] leading-relaxed">{info.response}</p>
                            </div>
                          )}

                          {/* Footer with timestamps */}
                          <div className="flex justify-between items-center pt-2 text-xs text-[#666666]">
                            <div className="flex items-center gap-1">
                              <Clock className="w-3 h-3" />
                              <span>Created: {new Date(info.created_at).toLocaleString()}</span>
                            </div>
                            {info.expires_at && (
                              <div className="flex items-center gap-1">
                                <Clock className="w-3 h-3" />
                                <span>Expires: {new Date(info.expires_at).toLocaleString()}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );

        case 'invitations':
          return (
            <div className="space-y-6">
              <div className="bg-white rounded-xl shadow-lg p-6">
                <div className="flex items-center mb-4">
                  <div className="bg-[#FFF3E6] p-3 rounded-full mr-4">
                    <Calendar className="text-[#FF6B35]" size={32} />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold text-[#2D3142]">Invitations</h2>
                    <p className="text-[#666666]">
                      {invitations.length} pending invitation{invitations.length !== 1 ? 's' : ''}
                    </p>
                  </div>
                </div>
        
                {invitations.length === 0 ? (
                  <div className="flex flex-col items-center justify-center p-8">
                    <Calendar className="text-[#FFB563] mb-4" size={64} />
                    <p className="text-[#2D3142]">No invitations at the moment</p>
                    <p className="text-[#666666] text-sm mt-2">Check back later for updates</p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {invitations.map(invitation => (
                      <div 
                        key={invitation.id}
                        className="bg-[#FFFAF6] rounded-lg p-4 border-l-4 border-[#FF6B35]"
                      >
                        <div className="flex items-start">
                          <UserCircle className="text-[#FF6B35] mr-4 mt-1" size={32} />
                          <div className="flex-grow">
                            <div className="flex justify-between items-center mb-2">
                              <h3 className="font-semibold text-[#2D3142]">
                                From {invitation.maintainer_name}
                              </h3>
                              <span className="text-xs text-[#666666]">
                                {new Date(invitation.created_at).toLocaleDateString()}
                              </span>
                            </div>
                            <p className="text-[#666666] mb-4">{invitation.details}</p>
                            <div className="flex justify-end gap-3">
                              <button
                                onClick={() => handleInvitationResponse(invitation.id, true)}
                                className="flex items-center gap-2 px-4 py-2 bg-[#4CAF50] text-white rounded-lg hover:bg-green-600 transition"
                              >
                                <CheckCircle size={16} />
                                Accept
                              </button>
                              <button
                                onClick={() => handleInvitationResponse(invitation.id, false)}
                                className="flex items-center gap-2 px-4 py-2 bg-[#FF5252] text-white rounded-lg hover:bg-red-600 transition"
                              >
                                ❌ Decline
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
        
        case 'notes':
          return (
            <div className="space-y-6">
              <div className="bg-white rounded-xl shadow-lg p-6">
                <div className="flex items-center mb-4">
                  <div className="bg-[#FFF3E6] p-3 rounded-full mr-4">
                    <BookOpen className="text-[#FF6B35]" size={32} />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold text-[#2D3142]">Sermon Notes</h2>
                    <p className="text-[#666666]">
                      {sermonNotes.length} note{sermonNotes.length !== 1 ? 's' : ''} available
                    </p>
                  </div>
                </div>
        
                {sermonNotes.length === 0 ? (
                  <div className="flex flex-col items-center justify-center p-8">
                    <FileText className="text-[#FFB563] mb-4" size={64} />
                    <p className="text-[#2D3142]">No sermon notes available</p>
                    <p className="text-[#666666] text-sm mt-2">Check back after sermons</p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {sermonNotes.map(note => (
                      <div 
                        key={note.id}
                        className="bg-[#FFFAF6] rounded-lg overflow-hidden"
                      >
                        <div 
                          onClick={() => setExpandedNoteId(expandedNoteId === note.id ? null : note.id)}
                          className="p-4 flex justify-between items-center cursor-pointer hover:bg-[#FFF3E6] transition"
                        >
                          <div className="flex items-center">
                            <BookmarkIcon className="text-[#FF6B35] mr-3" size={24} />
                            <h3 className="font-semibold text-[#2D3142]">{note.title}</h3>
                          </div>
                          {expandedNoteId === note.id ? (
                            <ChevronUp className="text-[#666666]" size={20} />
                          ) : (
                            <ChevronDown className="text-[#666666]" size={20} />
                          )}
                        </div>
        
                        {expandedNoteId === note.id && (
                          <div className="p-4 bg-white">
                            <p className="text-[#666666] mb-2">{note.description}</p>
                            <p className="text-xs text-[#666666]">
                              Created: {new Date(note.timestamp).toLocaleString()}
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );      
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-screen bg-[#FFFAF6]">
      <Toaster position="top-right" />
      
      {/* Absence Modal */}
      {isAbsenceModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-xl p-6 w-96">
            <h2 className="text-xl font-bold text-[#2D3142] mb-2">Record Absence</h2>
            <p className="text-sm text-gray-600 mb-4">
              Enter the 6-character code provided by your Ketua MK
            </p>

            <div className="space-y-4">
              {/* Fixed input container */}
              <div>
                <div className="relative border-2 border-[#FF6B35] rounded-lg overflow-hidden">
                  <input 
                    type="text"
                    value={absenceCode}
                    onChange={(e) => {
                      const value = e.target.value.toUpperCase();
                      if (value.length <= 6 && /^[A-Z0-9]*$/.test(value)) {
                        setAbsenceCode(value);
                      }
                    }}
                    maxLength={6}
                    className="w-full h-12 font-mono text-lg text-[#2D3142] text-center
                      focus:outline-none focus:ring-0 border-none"
                    style={{
                      caretColor: '#FF6B35', // Cursor color
                    }}
                  />
                </div>

                {/* Character count indicators */}
                <div className="flex items-center gap-1 mt-2">
                  <div className="flex gap-1">
                    {Array(6).fill(0).map((_, index) => (
                      <div 
                        key={index}
                        className={`h-1 w-8 rounded transition-colors ${
                          absenceCode[index] 
                            ? 'bg-[#FF6B35]' 
                            : 'bg-gray-200'
                        }`}
                      />
                    ))}
                  </div>
                </div>

                {/* Requirements */}
                <div className="mt-2 space-y-1">
                  <p className="text-sm text-gray-500 flex items-center">
                    <span className={absenceCode.length === 6 ? 'text-[#FF6B35]' : ''}>
                      ○ 6 characters long
                    </span>
                  </p>
                  <p className="text-sm text-gray-500 flex items-center">
                    <span className={/^[A-Z0-9]*$/.test(absenceCode) ? 'text-[#FF6B35]' : ''}>
                      ○ Only uppercase letters and numbers
                    </span>
                  </p>
                </div>

                {/* Example formats */}
                <div className="mt-4">
                  <p className="text-sm text-gray-500 mb-2">Example formats:</p>
                  <div className="flex gap-2">
                    {['ABC123', 'XYZ789', '123ABC'].map((example) => (
                      <button
                        key={example}
                        onClick={() => setAbsenceCode(example)}
                        className="px-3 py-1 text-sm bg-gray-50 rounded-md 
                          hover:bg-gray-100 transition-colors font-mono"
                      >
                        {example}
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              {/* Action buttons */}
              <div className="flex justify-end gap-3 mt-6">
                <button 
                  onClick={() => {
                    setIsAbsenceModalOpen(false);
                    setAbsenceCode('');
                  }}
                  className="px-6 py-2 text-gray-600 bg-gray-100 rounded-lg 
                    hover:bg-gray-200 transition-colors"
                >
                  Cancel
                </button>
                <button 
                  onClick={handleRecordAbsence}
                  className="px-6 py-2 bg-[#FF6B35] text-white rounded-lg
                    hover:opacity-90 transition-opacity disabled:opacity-50 
                    disabled:cursor-not-allowed"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Main Content */}
      <div className="flex-grow overflow-y-auto p-4">
        <div className="max-w-xl mx-auto">
          {renderContent()}
        </div>
      </div>

      {/* Navigation Bar */}
      <nav className="bg-white border-t border-gray-200 p-4">
        <div className="max-w-xl mx-auto flex justify-around">
          <button 
            onClick={() => setActiveTab('home')}
            className={`flex flex-col items-center ${
              activeTab === 'home' ? 'text-[#FF6B35]' : 'text-[#666666]'
            }`}
          >
            <Home size={24} />
            <span className="text-xs mt-1">Home</span>
          </button>

          <button 
            onClick={() => setActiveTab('profile')}
            className={`flex flex-col items-center ${
              activeTab === 'profile' ? 'text-[#FF6B35]' : 'text-[#666666]'
            }`}
          >
            <User size={24} />
            <span className="text-xs mt-1">Profile</span>
          </button>

          {userProfile?.maintainer ? (
            <>
              <button 
                onClick={() => setActiveTab('mk-info')}
                className={`flex flex-col items-center ${
                  activeTab === 'mk-info' ? 'text-[#FF6B35]' : 'text-[#666666]'
                }`}
              >
                <Building size={24} />
                <span className="text-xs mt-1">MK Info</span>
              </button>

              <button 
                onClick={() => setActiveTab('notes')}
                className={`flex flex-col items-center ${
                  activeTab === 'notes' ? 'text-[#FF6B35]' : 'text-[#666666]'
                }`}
              ><BookOpen size={24} />
                <span className="text-xs mt-1">Notes</span>
              </button>

              <button 
                onClick={() => setIsAbsenceModalOpen(true)}
                className="flex flex-col items-center text-[#666666]"
              >
                <Clock size={24} />
                <span className="text-xs mt-1">Absence</span>
              </button>
            </>
          ) : (
            <button 
              onClick={() => setActiveTab('invitations')}
              className={`flex flex-col items-center ${
                activeTab === 'invitations' ? 'text-[#FF6B35]' : 'text-[#666666]'
              }`}
            >
              <Calendar size={24} />
              <span className="text-xs mt-1">Invitations</span>
            </button>
          )}
        </div>
      </nav>
    </div>
  );
};

export default UserDashboard;
