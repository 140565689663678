import React, { useState, useEffect } from 'react';
import { 
  Home, 
  User,
  Users,
  UserPlus,
  Edit2,
  Trash2,
  Key,
  Clock,
  Mail,
  Cake,
  ChevronDown,
  ChevronUp,
  Plus,
  Quote,
  FileText,
  BookmarkIcon,
  Shield,
  MapPin,
  Phone,
  IdCard,
  UserCircle2,
  LogOut,
  Building,
  Flame,
  BookOpen,
  Calendar,
  CheckCircle,
  AlertTriangle
} from 'lucide-react';
import { api } from '../../services/api';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BirthdaySlider from '../../components/BirthdaySlider';
import { InputValidators } from '../../utils/validationUtils';
import EnhancedLoadingScreen from '../../components/EnhancedLoadingScreen';

// Enhanced DashboardCard component
interface DashboardCardProps {
  children: React.ReactNode;
  className?: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ children, className = '' }) => (
  <div className={`bg-white rounded-xl shadow-lg p-6 transition-all duration-300 
    hover:shadow-xl hover:border-l-4 hover:border-[#FF4B1F] ${className}`}>
    {children}
  </div>
);

// Interfaces
export interface Birthday {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string | null;
  maintainer?: {
    name: string;
  };
  super_maintainer?: {
    name: string;
  };
  admin?: {
    name: string;
  };
  role: string;
  service_info: any | null;
}

interface MaintainerProfile {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string;
  super_maintainer?: {
    name: string;
    phone_number: string;
  };
  admin?: {
    name: string;
    phone_number: string;
  };
  role: string;
}

interface BibleVerse {
  bookname: string;
  chapter: string;
  verse: string;
  text: string;
}

interface MeetingRoomRequest {
  id: number;
  title: string;
  description: string;
  meeting_datetime: string;
  location: string;
  maintainer_id: number;
  response: string;
  created_at: string;
  expires_at: string;
  status: string;
}

interface SermonNote {
  id: number;
  title: string;
  description: string;
  timestamp: string;
  maintainer_id: number;
}

interface MaintainedUser {
  name: string;
  phone_number: string;
  email: string;
  address: string;
  birthday: string;
  maintainer_id: number;
  id: number;
  created_at: string;
  absence_count?: number;
  maintainer: {
    id: number;
    name: string;
    username: string;
    phone_number: string;
    email: string;
  };
}

interface AvailableUser extends MaintainedUser {}

interface Invitation {
  id: number;
  super_maintainer_name: string;
  details: string;
  created_at: string;
}

const MaintainerDashboard: React.FC = () => {
  // State
  const [activeTab, setActiveTab] = useState('home');
  const [profile, setProfile] = useState<MaintainerProfile | null>(null);
  const [bibleVerse, setBibleVerse] = useState<BibleVerse | null>(null);
  const [meetingRoomRequests, setMeetingRoomRequests] = useState<MeetingRoomRequest[]>([]);
  const [notes, setNotes] = useState<SermonNote[]>([]);
  const [maintainedUsers, setMaintainedUsers] = useState<MaintainedUser[]>([]);
  const [availableUsers, setAvailableUsers] = useState<AvailableUser[]>([]);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [expandedNoteId, setExpandedNoteId] = useState<number | null>(null);
  const [expandedUserId, setExpandedUserId] = useState<number | null>(null);
  
  // Modals state
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isRequestMeetingModalOpen, setIsRequestMeetingModalOpen] = useState(false);
  const [isManualMeetingModalOpen, setIsManualMeetingModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isAbsenceTokenModalOpen, setIsAbsenceTokenModalOpen] = useState(false);
  const [customToken, setCustomToken] = useState('');
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState<MaintainedUser | null>(null);
  
  // Form states
  const [newNote, setNewNote] = useState({ title: '', description: '' });
  const [newUser, setNewUser] = useState({
    name: '',
    phone_number: '',
    email: '',
    address: '',
    birthday: '',
    password: ''
  });
  const [meetingRequest, setMeetingRequest] = useState({
    title: '',
    description: '',
    meeting_datetime: '',
    location: ''
  });
  
  const [manualMeeting, setManualMeeting] = useState({
    request: {
      title: '',
      description: '',
      meeting_datetime: '',
      location: ''
    },
    response: { response: '' }
  });
  const [editingUser, setEditingUser] = useState<MaintainedUser | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [birthdays, setBirthdays] = useState<Birthday[]>([]);
  const [titlePrefix, setTitlePrefix] = useState('MK');
  const [isLoading, setIsLoading] = useState(true);

  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        const response = await api.getTodaysBirthdays();
        setBirthdays(response.data);
      } catch (error) {
        console.error('Failed to load birthdays:', error);
        toast.error('Failed to load birthdays');
      }
    };
    fetchBirthdays();
  }, []);
  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch profile
        const profileResponse = await api.getMaintainerProfile();
        setProfile(profileResponse.data);

        // Fetch Bible verse
        const verseResponse = await axios.get('https://labs.bible.org/api/?passage=random&type=json&callback=myCallback', {
          transformResponse: [(data) => {
            const jsonMatch = data.match(/myCallback\((.*)\)/);
            return jsonMatch ? JSON.parse(jsonMatch[1]) : data;
          }]
        });
        
        if (Array.isArray(verseResponse.data) && verseResponse.data.length > 0) {
          setBibleVerse(verseResponse.data[0]);
        }

        // Fetch meeting room requests
        const meetingResponse = await api.getMeetingRoomRequests();
        setMeetingRoomRequests(meetingResponse.data);

        // Fetch notes
        const notesResponse = await api.getMaintainerNotes();
        setNotes(notesResponse.data);

        // Fetch maintained users
        const usersResponse = await api.getMaintainedUsers();
        setMaintainedUsers(usersResponse.data);

        const usersWithAbsence = await Promise.all(
          usersResponse.data.map(async (user: MaintainedUser) => {
            try {
              const absenceResponse = await api.getUserAbsenceCount(user.id);
              return { ...user, absence_count: absenceResponse.data.absence_count };
            } catch (error) {
              console.error(`Error fetching absence count for user ${user.id}:`, error);
              return { ...user, absence_count: 0 };
            }
          })
        );
        setMaintainedUsers(usersWithAbsence);

        // Fetch available users
        const availableResponse = await api.getAvailableUsers();
        setAvailableUsers(availableResponse.data);

        // Fetch invitations if no super_maintainer
        if (!profileResponse.data.super_maintainer) {
          const invitationsResponse = await api.getMaintainerInvitations();
          setInvitations(invitationsResponse.data);
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {
        toast.error('Failed to load dashboard data');
      }
    };

    fetchInitialData();
  }, []);

  if (isLoading) {
    return <EnhancedLoadingScreen />;
  }
  // Handlers
  const validateMeetingRequest = () => {
    // Title validation
    const titleError = InputValidators.validateMeetingTitle(meetingRequest.title);
    if (titleError) {
      toast.error(titleError);
      return false;
    }
  
    // Description validation
    const descriptionError = InputValidators.validateMeetingDescription(meetingRequest.description);
    if (descriptionError) {
      toast.error(descriptionError);
      return false;
    }
  
    // DateTime validation
    const dateTimeError = InputValidators.validateMeetingDateTime(meetingRequest.meeting_datetime);
    if (dateTimeError) {
      toast.error(dateTimeError);
      return false;
    }
  
    // Location validation
    const locationError = InputValidators.validateMeetingLocation(meetingRequest.location);
    if (locationError) {
      toast.error(locationError);
      return false;
    }
  
    return true;
  };
  
  const validateManualMeeting = () => {
    // Title validation
    const titleError = InputValidators.validateMeetingTitle(manualMeeting.request.title);
    if (titleError) {
      toast.error(titleError);
      return false;
    }
  
    // Description validation
    const descriptionError = InputValidators.validateMeetingDescription(manualMeeting.request.description);
    if (descriptionError) {
      toast.error(descriptionError);
      return false;
    }
  
    // DateTime validation
    const dateTimeError = InputValidators.validateMeetingDateTime(manualMeeting.request.meeting_datetime);
    if (dateTimeError) {
      toast.error(dateTimeError);
      return false;
    }
  
    // Location validation
    const locationError = InputValidators.validateMeetingLocation(manualMeeting.request.location);
    if (locationError) {
      toast.error(locationError);
      return false;
    }
  
    // Response validation
    const responseError = InputValidators.validateMeetingResponse(manualMeeting.response.response);
    if (responseError) {
      toast.error(responseError);
      return false;
    }
  
    return true;
  };

  const validateAddUserForm = () => {
    // Name validation
    const nameError = InputValidators.validateName(newUser.name);
    if (nameError) {
      toast.error(nameError);
      return false;
    }
  
    // Phone validation
    const phoneError = InputValidators.validatePhone(newUser.phone_number);
    if (phoneError) {
      toast.error(phoneError);
      return false;
    }
  
    // Email validation
    const emailError = InputValidators.validateEmail(newUser.email);
    if (emailError) {
      toast.error(emailError);
      return false;
    }
  
    // Address validation
    const addressError = InputValidators.validateAddress(newUser.address);
    if (addressError) {
      toast.error(addressError);
      return false;
    }
  
    // Birthday validation
    const birthdayError = InputValidators.validateBirthday(newUser.birthday);
    if (birthdayError) {
      toast.error(birthdayError);
      return false;
    }
  
    // Password validation
    const passwordError = InputValidators.validatePassword(newUser.password);
    if (passwordError) {
      toast.error(passwordError);
      return false;
    }
  
    return true;
  };

  const FORM_LIMITS = {
    // Meeting Room Requests
    TITLE_MIN: 5,
    TITLE_MAX: 100,
    DESCRIPTION_MIN: 10,
    DESCRIPTION_MAX: 500,
    LOCATION_MIN: 3,
    LOCATION_MAX: 50,
    
    // Manual Meeting Response
    RESPONSE_MIN: 10,
    RESPONSE_MAX: 500, // Added this line
    
    // Sermon Notes
    NOTE_TITLE_MIN: 3,
    NOTE_TITLE_MAX: 100,
    NOTE_DESCRIPTION_MIN: 10,
    NOTE_DESCRIPTION_MAX: 2000,
};

  const validateEditUserForm = () => {
    if (!editingUser) return false;
  
    // Name validation
    const nameError = InputValidators.validateName(editingUser.name);
    if (nameError) {
      toast.error(nameError);
      return false;
    }
  
    // Phone validation
    const phoneError = InputValidators.validatePhone(editingUser.phone_number);
    if (phoneError) {
      toast.error(phoneError);
      return false;
    }
  
    // Email validation
    const emailError = InputValidators.validateEmail(editingUser.email);
    if (emailError) {
      toast.error(emailError);
      return false;
    }
  
    // Address validation
    const addressError = InputValidators.validateAddress(editingUser.address);
    if (addressError) {
      toast.error(addressError);
      return false;
    }
  
    // Birthday validation
    const birthdayError = InputValidators.validateBirthday(editingUser.birthday);
    if (birthdayError) {
      toast.error(birthdayError);
      return false;
    }
  
    return true;
  };
  
  const handleSubmitCustomToken = async () => {
    const tokenError = InputValidators.validateAbsenceToken(customToken);
    if (tokenError) {
      toast.error(tokenError);
      return;
    }
  
    try {
      await api.generateAbsenceToken(customToken);
      setIsAbsenceTokenModalOpen(false);
      setCustomToken('');
      toast.success('Absence token generated successfully');
    } catch (error) {
      toast.error('Failed to generate absence token');
    }
  };


  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
    toast.success('Logged out successfully');
  };

  const handleAddNote = async () => {
    try {
      await api.createNote(newNote);
      const notesResponse = await api.getMaintainerNotes();
      setNotes(notesResponse.data);
      setIsAddNoteModalOpen(false);
      setNewNote({ title: '', description: '' });
      toast.success('Note added successfully');
    } catch (error) {
      toast.error('Failed to add note');
    }
  };

  const handleAddUser = async () => {
    if (!validateAddUserForm()) {
      return;
    }
    try {
      await api.createUser(newUser);
      const usersResponse = await api.getMaintainedUsers();
      setMaintainedUsers(usersResponse.data);
      setIsAddUserModalOpen(false);
      setNewUser({ name: '', phone_number: '', email: '', address: '', birthday:'', password: '' });
      toast.success('User added successfully');
    } catch (error) {
      toast.error('Failed to add user');
    }
  };

  const handleRequestMeeting = async () => {
    if (!validateMeetingRequest()) {
      return;
    }
    
    try {
      await api.requestMeetingRoom(meetingRequest);
      const meetingResponse = await api.getMeetingRoomRequests();
      setMeetingRoomRequests(meetingResponse.data);
      setIsRequestMeetingModalOpen(false);
      setMeetingRequest({
        title: '',
        description: '',
        meeting_datetime: '',
        location: ''
      });
      toast.success('Meeting room requested successfully');
    } catch (error) {
      toast.error('Failed to request meeting room');
    }
  };
  
  const handleManualMeeting = async () => {
    if (!validateManualMeeting()) {
      return;
    }
    
    try {
      await api.setMeetingRoomManually(manualMeeting);
      const meetingResponse = await api.getMeetingRoomRequests();
      setMeetingRoomRequests(meetingResponse.data);
      setIsManualMeetingModalOpen(false);
      setManualMeeting({
        request: {
          title: '',
          description: '',
          meeting_datetime: '',
          location: ''
        },
        response: { response: '' }
      });
      toast.success('Meeting room set manually');
    } catch (error) {
      toast.error('Failed to set meeting room');
    }
  };

  const handleUpdateUser = async () => {
    if (!editingUser || !validateEditUserForm()) {
      return;
    }
    try {
      await api.updateUser(editingUser.id, editingUser);
      const usersResponse = await api.getMaintainedUsers();
      setMaintainedUsers(usersResponse.data);
      setIsEditUserModalOpen(false);
      setEditingUser(null);
      toast.success('User updated successfully');
    } catch (error) {
      toast.error('Failed to update user');
    }
  };

  const handleResetPassword = async (userId: number) => {
    const passwordError = InputValidators.validatePassword(newPassword);
    if (passwordError) {
      toast.error(passwordError);
      return;
    }

    try {
      await api.resetUserPassword(userId, { new_password: newPassword });
      setIsResetPasswordModalOpen(false);
      setNewPassword('');
      toast.success('Password reset successfully');
    } catch (error) {
      toast.error('Failed to reset password');
    }
  };

  const handleRemoveUser = async (userId: number) => {
    try {
      await api.removeUser(userId);
      const usersResponse = await api.getMaintainedUsers();
      setMaintainedUsers(usersResponse.data);
      setShowRemoveUserModal(false);
      setUserToRemove(null);
      toast.success('User removed successfully', {
        icon: '👋',
        duration: 4000
      });
    } catch (error) {
      toast.error('Failed to remove user');
    }
  };

  const renderRemoveUserModal = () => (
    showRemoveUserModal && userToRemove && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-red-500 transform transition-all">
          <div className="text-center">
            {/* Warning Icon */}
            <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100 mb-4">
              <Trash2 className="h-8 w-8 text-red-500" />
            </div>
            
            {/* Modal Content */}
            <h3 className="text-xl font-bold text-gray-900 mb-2">
              Remove User
            </h3>
            <div className="bg-gray-50 rounded-lg p-4 mb-4">
              <p className="text-sm text-gray-600 mb-2">
                You are about to remove:
              </p>
              <div className="flex items-center justify-center space-x-2 text-gray-800">
                <User className="h-5 w-5 text-gray-500" />
                <span className="font-medium">{userToRemove.name}</span>
              </div>
              <div className="text-xs text-gray-500 mt-2">
                {userToRemove.phone_number}
              </div>
            </div>
            
            <p className="text-sm text-gray-500 mb-6">
              This action will remove the user from your maintained list. 
              The user will need to be re-added to restore access.
            </p>

            {/* Action Buttons */}
            <div className="flex justify-center space-x-3">
              <button
                onClick={() => {
                  setShowRemoveUserModal(false);
                  setUserToRemove(null);
                }}
                className="px-6 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-full 
                  transition-all duration-200 flex items-center space-x-2"
              >
                Cancel
              </button>
              <button
                onClick={() => handleRemoveUser(userToRemove.id)}
                className="px-6 py-2 bg-red-500 hover:bg-red-600 text-white rounded-full 
                  transition-all duration-200 flex items-center space-x-2 group"
              >
                <Trash2 className="h-4 w-4 group-hover:rotate-12 transition-transform" />
                <span>Remove User</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
  
  const handleGenerateAbsenceToken = async () => {
    setIsAbsenceTokenModalOpen(true);
  };
  
  const handleInviteUser = async (userId: number) => {
    try {
      await api.inviteUser(userId);
      toast.success('Invitation sent successfully');
    } catch (error) {
      toast.error('Failed to send invitation');
    }
  };

  const handleDeleteUser = async (userId: number) => {
    try {
      await api.deleteUser(userId);
      const availableResponse = await api.getAvailableUsers();
      setAvailableUsers(availableResponse.data);
      setShowDeleteConfirm(false);
      setSelectedUserId(null);
      toast.success('User deleted successfully');
    } catch (error) {
      toast.error('Failed to delete user');
    }
  };
  const renderDeleteUserModal = () => (
    showDeleteConfirm && selectedUserId && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-red-500 transform transition-all">
          <div className="text-center">
            {/* Warning Icon */}
            <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100 mb-4">
              <Trash2 className="h-8 w-8 text-red-500" />
            </div>
            
            {/* Modal Content */}
            <h3 className="text-xl font-bold text-gray-900 mb-2">
              Remove User
            </h3>
            <p className="text-sm text-gray-500 mb-6">
              This action will delete the user forever. 
              The user will need to be register again.
            </p>

            {/* Action Buttons */}
            <div className="flex justify-center space-x-3">
              <button
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setSelectedUserId(null);
                }}
                className="px-6 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-full 
                  transition-all duration-200 flex items-center space-x-2"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteUser(selectedUserId)}
                className="px-6 py-2 bg-red-500 hover:bg-red-600 text-white rounded-full 
                  transition-all duration-200 flex items-center space-x-2 group"
              >
                <Trash2 className="h-4 w-4 group-hover:rotate-12 transition-transform" />
                <span>Remove User</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
  const handleInvitationResponse = async (invitationId: number, accepted: boolean) => {
    try {
      await api.respondToMaintainerInvitation(invitationId, accepted);
      const invitationsResponse = await api.getMaintainerInvitations();
      setInvitations(invitationsResponse.data);
      toast.success(accepted ? 'Invitation accepted' : 'Invitation declined');
    } catch (error) {
      toast.error('Failed to respond to invitation');
    }
  };

  const getAbsenceColor = (count: number) => {
    if (count >= 8) return 'bg-red-200 text-red-800';
    if (count >= 5) return 'bg-red-100 text-red-600';
    if (count >= 3) return 'bg-orange-100 text-orange-600';
    if (count > 0) return 'bg-yellow-100 text-yellow-600';
    return 'bg-green-100 text-green-600';
  };

  const handlePrefixedTitleChange = (baseTitle: string, stateUpdater: Function, currentState: any) => {
    stateUpdater({ ...currentState, title: `${titlePrefix} ${baseTitle}` });
  };

  // Render functions
  const renderContent = () => {
    switch (activeTab) {
      case 'home':
        return (
          <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
            <div className="max-w-xl mx-auto space-y-6">
              {/* Enhanced Greeting Section */}
              <DashboardCard>
                <div className="flex items-center mb-4">
                  <div className="p-3 bg-[#FF4B1F] bg-opacity-10 rounded-full mr-4">
                    <Flame className="text-[#FF4B1F]" size={32} />
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold bg-gradient-to-r from-[#FF4B1F] to-[#FFB75E] bg-clip-text text-transparent">
                      Welcome back, {profile?.name}!
                    </h1>
                    <p className="text-gray-600">
                      Ketua MK Dashboard
                    </p>
                  </div>
                </div>
              </DashboardCard>

              {/* Enhanced Bible Verse Section */}
              {bibleVerse && (
                <DashboardCard className="hover:bg-[#FFF9F5]">
                  <div className="flex items-start">
                    <div className="p-2 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
                      <Quote className="text-[#1E3799]" size={24} />
                    </div>
                    <div>
                      <p className="italic text-gray-700 mb-3 font-serif text-lg">
                        "{bibleVerse.text}"
                      </p>
                      <p className="text-[#1E3799] font-medium">
                        {bibleVerse.bookname} {bibleVerse.chapter}:{bibleVerse.verse}
                      </p>
                    </div>
                  </div>
                </DashboardCard>
              )}
              <BirthdaySlider birthdays={birthdays} />
            </div>
          </div>
        );

      case 'profile':
        return (
          <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
            <div className="max-w-xl mx-auto">
              {/* Enhanced Profile Header */}
              <DashboardCard className="mb-6">
                <div className="flex items-center">
                  <div className="p-4 bg-[#FF4B1F] bg-opacity-10 rounded-full mr-6">
                    <UserCircle2 className="text-[#FF4B1F]" size={48} />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold bg-gradient-to-r from-[#FF4B1F] to-[#FFB75E] bg-clip-text text-transparent">
                      {profile?.name}
                    </h2>
                    <p className="text-gray-600">Ketua MK</p>
                  </div>
                </div>
              </DashboardCard>

              {/* Enhanced Profile Details */}
              <DashboardCard className="mb-6">
                <h3 className="text-xl font-semibold text-[#1E3799] mb-6 flex items-center">
                  <IdCard className="mr-2" size={24} />
                  Personal Information
                </h3>
                
                <div className="space-y-6">
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <Phone className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Phone Number</p>
                      <p className="text-gray-600">{profile?.phone_number}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <MapPin className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Address</p>
                      <p className="text-gray-600">{profile?.address}</p>
                    </div>
                  </div>

                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <Cake className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Birthday</p>
                      <p className="text-gray-600">{profile?.birthday}</p>
                    </div>
                  </div>
                </div>
              </DashboardCard>

              {/* Enhanced Leadership Connections */}
              {(profile?.super_maintainer || profile?.admin) && (
                <DashboardCard className="mb-6">
                  <h3 className="text-xl font-semibold text-[#1E3799] mb-6 flex items-center">
                    <Shield className="mr-2" size={24} />
                    Leadership Connections
                  </h3>
                  
                  <div className="space-y-4">
                    {profile?.super_maintainer && (
                      <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                        <div className="p-2 bg-[#8E44AD] bg-opacity-10 rounded-full mr-3">
                          <Shield className="text-[#8E44AD]" size={20} />
                        </div>
                        <div>
                          <p className="font-medium text-gray-700">Ketua Rayon</p>
                          <p className="text-gray-600">{profile.super_maintainer.name}</p>
                          <p className="text-sm text-gray-500">{profile.super_maintainer.phone_number}</p>
                        </div>
                      </div>
                    )}

                    {profile?.admin && (
                      <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                        <div className="p-2 bg-[#FF4B1F] bg-opacity-10 rounded-full mr-3">
                          <Shield className="text-[#FF4B1F]" size={20} />
                        </div>
                        <div>
                          <p className="font-medium text-gray-700">Leader</p>
                          <p className="text-gray-600">{profile.admin.name}</p>
                          <p className="text-sm text-gray-500">{profile.admin.phone_number}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </DashboardCard>
              )}

              {/* Enhanced Logout Button */}
              <div className="flex justify-center">
                <button 
                  onClick={handleLogout}
                  className="bg-[#FF4B1F] text-white px-8 py-3 rounded-full hover:bg-opacity-90 
                    transition-all duration-300 transform hover:scale-105 flex items-center gap-2 shadow-lg"
                >
                  <LogOut size={20} />
                  <span className="font-medium">Logout</span>
                </button>
              </div>
            </div>
          </div>
        );

        case 'mk-info':
          return (
            <div className="p-4 pb-20 bg-gradient-to-b from-[#FFF9F5] to-white min-h-full">
              <div className="max-w-xl mx-auto">
                {/* Enhanced Header Card */}
                <DashboardCard className="mb-6 hover:shadow-xl transition-all duration-300">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <div className="p-3 bg-gradient-to-br from-[#1E3799] to-[#4A69BD] rounded-full mr-4 shadow-lg">
                        <Building className="text-white" size={32} />
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                          MK Location
                        </h2>
                        <p className="text-gray-600">
                          Manage your meeting schedules
                        </p>
                      </div>
                    </div>
                    <div className="space-x-2 flex items-center">
                      <button
                        onClick={() => setIsRequestMeetingModalOpen(true)}
                        className="p-3 bg-gradient-to-r from-[#1E3799] to-[#4A69BD] text-white rounded-full
                          hover:opacity-90 transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
                      >
                        <Plus size={18} />
                      </button>
                      <button
                        onClick={() => setIsManualMeetingModalOpen(true)}
                        className="p-3 bg-gradient-to-r from-[#8E44AD] to-[#9B59B6] text-white rounded-full
                          hover:opacity-90 transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl"
                      >
                        <Calendar size={18} />
                      </button>
                    </div>
                  </div>
                </DashboardCard>
        
                {meetingRoomRequests.length === 0 ? (
                  <DashboardCard className="text-center py-12 hover:shadow-xl transition-all duration-300">
                    <Building className="text-gray-300 mx-auto mb-4" size={64} />
                    <p className="text-gray-500 font-medium">No meeting room requests</p>
                    <p className="text-gray-400 text-sm mt-2">Create a new request to get started</p>
                    <button
                      onClick={() => setIsRequestMeetingModalOpen(true)}
                      className="mt-6 px-6 py-3 bg-gradient-to-r from-[#1E3799] to-[#4A69BD] text-white rounded-full
                        hover:opacity-90 transition-all duration-300 transform hover:scale-105 shadow-lg hover:shadow-xl
                        flex items-center justify-center mx-auto gap-2"
                    >
                      <Plus size={18} />
                      New Request
                    </button>
                  </DashboardCard>
                ) : (
                  <div className="space-y-4">
                    {meetingRoomRequests.map(request => (
                      <DashboardCard key={request.id} className="hover:shadow-xl transition-all duration-300 group">
                        <div className="flex items-start justify-between mb-3">
                          <div className="flex items-center">
                            <div className="p-2 bg-gradient-to-br from-[#1E3799] to-[#4A69BD] rounded-full mr-3 
                              group-hover:shadow-lg transition-all duration-300">
                              <Building className="text-white" size={20} />
                            </div>
                            <span className="font-semibold text-gray-800">{request.title}</span>
                          </div>
                          <span className={`px-4 py-2 rounded-full text-sm font-medium shadow-sm transition-all duration-300 ${
                            request.status === 'approved'
                              ? 'bg-gradient-to-r from-green-100 to-green-200 text-green-700'
                              : request.status === 'pending'
                              ? 'bg-gradient-to-r from-yellow-100 to-yellow-200 text-yellow-700'
                              : 'bg-gradient-to-r from-red-100 to-red-200 text-red-700'
                          }`}>
                            {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                          </span>
                        </div>
        
                        <p className="text-gray-700 leading-relaxed mb-3">
                          {request.description}
                        </p>
        
                        <div className="bg-gradient-to-r from-[#F5F6FA] to-white p-4 rounded-lg mb-3 
                          group-hover:shadow-md transition-all duration-300">
                          <div className="flex justify-between items-center">
                            <span className="text-gray-600 flex items-center">
                              <Calendar className="mr-2" size={16} />
                              {new Date(request.meeting_datetime).toLocaleString()}
                            </span>
                            <span className="text-gray-600 flex items-center">
                              <MapPin className="mr-2" size={16} />
                              {request.location}
                            </span>
                          </div>
                        </div>
        
                        {request.response && (
                          <div className="bg-gradient-to-r from-[#F5F6FA] to-white p-4 rounded-lg w-full 
                            group-hover:shadow-md transition-all duration-300">
                            <p className="text-gray-600 italic">
                              <span className="font-medium">Response:</span> {request.response}
                            </p>
                          </div>
                        )}
        
                        <div className="mt-4 pt-3 border-t border-gray-100 text-sm text-gray-500 flex justify-between items-center">
                          <div className="flex items-center">
                            <Clock size={16} className="mr-1" />
                            <span>Created: {new Date(request.created_at).toLocaleString()}</span>
                          </div>
                          <div className="flex items-center">
                            <Calendar size={16} className="mr-1" />
                            <span>Expires: {new Date(request.expires_at).toLocaleString()}</span>
                          </div>
                        </div>
                      </DashboardCard>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );        
          case 'notes':
            return (
              <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
                <div className="max-w-xl mx-auto">
                  {/* Enhanced Header */}
                  <DashboardCard className="mb-6">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="p-3 bg-[#2ECC71] bg-opacity-10 rounded-full mr-4">
                          <BookOpen className="text-[#2ECC71]" size={32} />
                        </div>
                        <div>
                          <h2 className="text-2xl font-bold bg-gradient-to-r from-[#2ECC71] to-[#1E3799] bg-clip-text text-transparent">
                            Sermon Notes
                          </h2>
                          <p className="text-gray-600">
                            {notes.length} note{notes.length !== 1 ? 's' : ''}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => setIsAddNoteModalOpen(true)}
                        className="p-3 bg-[#2ECC71] text-white rounded-full
                          hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                      >
                        <Plus size={18} />
                      </button>
                    </div>
                  </DashboardCard>
        
                {/* Notes List */}
                {notes.length === 0 ? (
                  <DashboardCard className="text-center py-12">
                    <FileText className="text-gray-300 mx-auto mb-4" size={64} />
                    <p className="text-gray-500">No sermon notes available</p>
                    <p className="text-gray-400 text-sm mt-2">Create your first note</p>
                  </DashboardCard>
                ) : (
                  <div className="space-y-4">
                    {notes.map(note => (
                      <DashboardCard key={note.id} className="overflow-hidden">
                        <div className="cursor-pointer">
                          <div className="flex justify-between items-center">
                            <div className="flex items-center">
                              <div className="p-2 bg-[#2ECC71] bg-opacity-10 rounded-full mr-3">
                                <BookmarkIcon className="text-[#2ECC71]" size={20} />
                              </div>
                              <h3 className="font-semibold text-gray-800 truncate max-w-[200px]">
                                {note.title}
                              </h3>
                            </div>
                            <button
                              onClick={() => setExpandedNoteId(expandedNoteId === note.id ? null : note.id)}
                              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                            >
                              {expandedNoteId === note.id ? (
                                <ChevronUp className="text-gray-500" size={20} />
                              ) : (
                                <ChevronDown className="text-gray-500" size={20} />
                              )}
                            </button>
                          </div>
                          
                          {expandedNoteId === note.id && (
                            <div className="mt-4 pl-11">
                              <div className="bg-[#F5F6FA] p-4 rounded-lg">
                                <p className="text-gray-700 mb-2 whitespace-pre-wrap break-words">
                                  {note.description}
                                </p>
                                <p className="text-xs text-gray-500 mt-2">
                                  Created: {new Date(note.timestamp).toLocaleString()}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </DashboardCard>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );        
          case 'users':
            return (
              <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full relative">
                <div className="max-w-xl mx-auto">
                  {/* Enhanced Header */}
                  <DashboardCard className="mb-6">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <div className="p-3 bg-[#8E44AD] bg-opacity-10 rounded-full mr-4">
                          <Users className="text-[#8E44AD]" size={32} />
                        </div>
                        <div>
                          <h2 className="text-2xl font-bold bg-gradient-to-r from-[#8E44AD] to-[#2ECC71] bg-clip-text text-transparent">
                            Discipleship
                          </h2>
                          <p className="text-gray-600">
                            {maintainedUsers.length} user{maintainedUsers.length !== 1 ? 's' : ''}
                          </p>
                        </div>
                      </div>
                      <button
                        onClick={() => setIsAddUserModalOpen(true)}
                        className="p-3 bg-[#8E44AD] text-white rounded-full
                          hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                      >
                        <UserPlus size={18} />
                      </button>
                    </div>
                  </DashboardCard>
        
                {/* Users List */}
                {maintainedUsers.length === 0 ? (
                  <DashboardCard className="text-center py-12">
                    <Users className="text-gray-300 mx-auto mb-4" size={64} />
                    <p className="text-gray-500">No maintained users</p>
                    <p className="text-gray-400 text-sm mt-2">Add your first user</p>
                  </DashboardCard>
                ) : (
                  <div className="space-y-4">
                    {maintainedUsers.map(user => (
                      <DashboardCard key={user.id}>
                        <div
                          onClick={() => setExpandedUserId(expandedUserId === user.id ? null : user.id)}
                          className="cursor-pointer"
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex items-center">
                              <div className="p-2 bg-[#8E44AD] bg-opacity-10 rounded-full mr-3">
                                <User className="text-[#8E44AD]" size={20} />
                              </div>
                              <div>
                                <h3 className="font-semibold text-gray-800">{user.name}</h3>
                                <p className="text-sm text-gray-500">{user.phone_number}</p>
                                <div className={`mt-1 inline-flex items-center px-2 py-1 rounded-full text-xs font-medium
                                ${getAbsenceColor(user.absence_count || 0)}`}
                                >
                                  <AlertTriangle 
                                    className="w-3 h-3 mr-1" 
                                  />
                                  {user.absence_count || 0} absences
                                </div>
                              </div>
                            </div>
                            {expandedUserId === user.id ? (
                              <ChevronUp className="text-gray-500" size={20} />
                            ) : (
                              <ChevronDown className="text-gray-500" size={20} />
                            )}
                          </div>
                          
                          {expandedUserId === user.id && (
                            <div className="mt-4 pl-11">
                              <div className="bg-[#F5F6FA] p-4 rounded-lg space-y-3">
                                <div className="flex items-center">
                                  <Cake className="text-[#8E44AD] mr-2" size={16} />
                                  <p className="text-gray-700">{user.birthday}</p>
                                </div>
                                <div className="flex items-center">
                                  <MapPin className="text-[#8E44AD] mr-2" size={16} />
                                  <p className="text-gray-700">{user.address}</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                  Joined: {new Date(user.created_at).toLocaleString()}
                                </p>
                                
                                <div className="flex gap-2 pt-3">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setEditingUser(user);
                                      setIsEditUserModalOpen(true);
                                    }}
                                    className="p-2 bg-[#1E3799] bg-opacity-10 hover:bg-opacity-20 text-[#1E3799] 
                                      rounded-full transition-all duration-300"
                                  >
                                    <Edit2 size={16} />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setEditingUser(user);
                                      setIsResetPasswordModalOpen(true);
                                    }}
                                    className="p-2 bg-[#FFB75E] bg-opacity-10 hover:bg-opacity-20 text-[#FFB75E] 
                                      rounded-full transition-all duration-300"
                                  >
                                    <Key size={16} />
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setUserToRemove(user);
                                      setShowRemoveUserModal(true);
                                    }}
                                    className="p-2 bg-red-100 hover:bg-red-200 text-red-500 
                                    rounded-full transition-all duration-300 group"
                                  >
                                    <Trash2 
                                      size={16} 
                                      className="group-hover:rotate-12 transition-transform" 
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </DashboardCard>
                    ))}
                  </div>
                )}
              </div>     
              <button
                onClick={handleGenerateAbsenceToken}
                className="fixed bottom-24 right-4 md:right-8 flex items-center gap-2 
                  bg-[#2ECC71] text-white px-4 py-3 rounded-full shadow-lg 
                  hover:shadow-xl transition-all duration-300 transform hover:scale-105
                  z-20"
              >
                <Clock size={20} />
                <span className="font-medium">Generate Absence Token</span>
              </button>
            </div>
          );

          case 'soul':
            return (
              <div className="p-4 bg-[#FFF9F5] min-h-full">
                <div className="max-w-xl mx-auto">
                  {/* Header */}
                  <DashboardCard className="mb-6">
                    <div className="flex items-center">
                      <div className="p-3 bg-[#8E44AD] bg-opacity-10 rounded-full mr-4">
                        <Users className="text-[#8E44AD]" size={32} />
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold bg-gradient-to-r from-[#8E44AD] to-[#1E3799] bg-clip-text text-transparent">
                          Available Users
                        </h2>
                        <p className="text-gray-600">
                          Find and invite new community members
                        </p>
                      </div>
                    </div>
                  </DashboardCard>    
    
                  {/* Available Users List */}
                  {availableUsers.length === 0 ? (
                    <DashboardCard className="text-center py-12">
                      <Users className="text-gray-300 mx-auto mb-4" size={64} />
                      <p className="text-gray-500">No available users</p>
                      <p className="text-gray-400 text-sm mt-2">Check back later</p>
                    </DashboardCard>
                  ) : (
                    <div className="space-y-4">
                      {availableUsers.map(user => (
                        <DashboardCard key={user.id} className="hover:border-[#8E44AD]">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <div className="p-2 bg-[#8E44AD] bg-opacity-10 rounded-full mr-3">
                              <User className="text-[#8E44AD]" size={24} />
                            </div>
                            <div>
                              <h3 className="font-semibold text-gray-800">{user.name}</h3>
                              <div className="flex items-center text-sm text-gray-500 mt-1">
                                <Phone className="w-4 h-4 mr-1" />
                                {user.phone_number}
                              </div>
                              <div className="flex items-center text-sm text-gray-500 mt-1">
                                <Cake className="w-4 h-4 mr-1" />
                                {user.birthday}
                              </div>
                              <div className="flex items-center text-sm text-gray-500 mt-1">
                                <MapPin className="w-4 h-4 mr-1" />
                                {user.address}
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center space-x-3 mt-4">
                            <button
                              onClick={() => handleInviteUser(user.id)}
                              className="p-3 bg-[#2ECC71] text-white rounded-full hover:bg-opacity-90 
                                transition-all duration-300 transform hover:scale-105 shadow-md"
                            >
                              <UserPlus size={18} />
                            </button>
                            <button
                              onClick={() => {
                                setSelectedUserId(user.id);
                                setShowDeleteConfirm(true);
                              }}
                              className="p-3 bg-[#FF4B1F] text-white rounded-full hover:bg-opacity-90 
                                transition-all duration-300 transform hover:scale-105 shadow-md"
                            >
                              <Trash2 size={18} />
                            </button>
                          </div>
                        </div>
                      </DashboardCard>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            );
    
          case 'invitations':
            return (
              <div className="p-4 bg-[#FFF9F5] min-h-full">
                <div className="max-w-xl mx-auto">
                  {/* Header */}
                  <DashboardCard className="mb-6">
                    <div className="flex items-center">
                      <div className="p-3 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
                        <Mail className="text-[#1E3799]" size={32} />
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                          Invitations
                        </h2>
                        <p className="text-gray-600">
                          {invitations.length === 0 
                            ? "No new invitations" 
                            : `You have ${invitations.length} pending invitation${invitations.length > 1 ? 's' : ''}`}
                        </p>
                      </div>
                    </div>
                  </DashboardCard>
    
                  {/* Invitations List */}
                  {invitations.length === 0 ? (
                    <DashboardCard className="text-center py-12">
                      <Mail className="text-gray-300 mx-auto mb-4" size={64} />
                      <p className="text-gray-500">No invitations at the moment</p>
                      <p className="text-gray-400 text-sm mt-2">Check back later for updates</p>
                    </DashboardCard>
                  ) : (
                    <div className="space-y-4">
                      {invitations.map(invitation => (
                        <DashboardCard key={invitation.id} className="hover:border-[#1E3799]">
                          <div className="flex items-start">
                            <div className="p-2 bg-[#1E3799] bg-opacity-10 rounded-full mr-3">
                              <Shield className="text-[#1E3799]" size={24} />
                            </div>
                            <div className="flex-grow">
                              <div className="flex justify-between items-center">
                                <h3 className="font-semibold text-gray-800">
                                  Invitation from {invitation.super_maintainer_name}
                                </h3>
                                <span className="text-xs text-gray-500">
                                  {new Date(invitation.created_at).toLocaleDateString()}
                                </span>
                              </div>
                              <p className="text-gray-600 text-sm mt-2">{invitation.details}</p>
                              <div className="flex justify-end gap-3 mt-4">
                                <button 
                                  onClick={() => handleInvitationResponse(invitation.id, true)}
                                  className="flex items-center gap-2 px-4 py-2 bg-[#1E3799] text-white rounded-full 
                                    hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                                >
                                  <CheckCircle size={16} />
                                  Accept
                                </button>
                                <button 
                                  onClick={() => handleInvitationResponse(invitation.id, false)}
                                  className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-full 
                                    hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                                >
                                  <Trash2 size={16} />
                                  Decline
                                </button>
                              </div>
                            </div>
                          </div>
                        </DashboardCard>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            );

      default:
        return null;
    }
  };


  return (
    <div className="flex flex-col h-screen bg-[#FFF9F5]">
      <Toaster position="top-right" />
      
      {/* Main Content Area */}
      <div className="flex-grow overflow-y-auto">
        {renderContent()}
      </div>
      {renderRemoveUserModal()}
      {renderDeleteUserModal()}
      {/* Enhanced Navigation */}
      <nav className="bg-white border-t border-gray-100 py-3 px-4 flex justify-around fixed bottom-0 left-0 right-0 z-10">
        {[
          { id: 'home', icon: Home, label: 'Home' },
          { id: 'profile', icon: User, label: 'Profile' },
          { id: 'mk-info', icon: Building, label: 'MK Info' },
          { id: 'notes', icon: BookOpen, label: 'Notes' },
          { id: 'users', icon: Users, label: 'Users' },
          { id: 'soul', icon: Flame, label: 'Soul' },
          ...((!profile?.super_maintainer) ? [{ id: 'invitations', icon: Mail, label: 'Invites' }] : [])
        ].map(item => (
          <button 
            key={item.id}
            onClick={() => setActiveTab(item.id)} 
            className={`flex flex-col items-center transition-all duration-300 ${
              activeTab === item.id 
                ? 'text-[#FF4B1F] transform scale-110' 
                : 'text-gray-400 hover:text-[#FF4B1F]'
            }`}
          >
            <item.icon size={20} />
            <span className="text-xs mt-1 font-medium">{item.label}</span>
          </button>
        ))}
      </nav>

      {/* Add bottom padding to content to prevent overlap with fixed navbar */}
      <div className="h-16"></div>

      {/* Your existing modals with enhanced styling... */}
      {isAddNoteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#2ECC71]">
            <h2 className="text-xl font-bold mb-6 text-[#2ECC71]">Add New Note</h2>
            <div className="space-y-4">
              <div className="space-y-2">
                <input 
                  type="text"
                  placeholder="Title"
                  value={newNote.title}
                  onChange={(e) => setNewNote({ ...newNote, title: e.target.value })}
                  minLength={FORM_LIMITS.NOTE_TITLE_MIN}
                  maxLength={FORM_LIMITS.NOTE_TITLE_MAX}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#2ECC71] 
                    focus:border-transparent"
                  required
                />
                <div className="flex justify-between text-sm text-gray-500">
                  <span>{newNote.title.length} characters</span>
                  <span>{FORM_LIMITS.NOTE_TITLE_MAX - newNote.title.length} remaining</span>
                </div>
              </div>

              <div className="space-y-2">
                <textarea
                  placeholder="Description"
                  value={newNote.description}
                  onChange={(e) => setNewNote({ ...newNote, description: e.target.value })}
                  minLength={FORM_LIMITS.NOTE_DESCRIPTION_MIN}
                  maxLength={FORM_LIMITS.NOTE_DESCRIPTION_MAX}
                  className="w-full p-3 border rounded-lg h-48 focus:ring-2 focus:ring-[#2ECC71] 
                    focus:border-transparent resize-none"
                  required
                />
                <div className="flex justify-between text-sm text-gray-500">
                  <span>{newNote.description.length} characters</span>
                  <span>{FORM_LIMITS.NOTE_DESCRIPTION_MAX - newNote.description.length} remaining</span>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-3 mt-4">
              <button
                onClick={() => setIsAddNoteModalOpen(false)}
                className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={handleAddNote}
                disabled={
                  newNote.title.length < FORM_LIMITS.NOTE_TITLE_MIN ||
                  newNote.description.length < FORM_LIMITS.NOTE_DESCRIPTION_MIN
                }
                className="px-6 py-2 bg-[#2ECC71] text-white rounded-full hover:bg-opacity-90 
                  transition-all disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Add Note
              </button>
            </div>
          </div>
        </div>
      )}

    {/* Add User Modal */}
    {isAddUserModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#8E44AD]">
          <h2 className="text-xl font-bold mb-6 text-[#8E44AD]">Add New User</h2>
          <div className="space-y-4">
            <div>
              <input 
                type="text"
                placeholder="Name (letters, spaces, and hyphens only)"
                value={newUser.name}
                onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                pattern="^[a-zA-Z\s-]{2,100}$"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="tel"
                placeholder="Phone Number (+62/0)"
                value={newUser.phone_number}
                onChange={(e) => setNewUser({ ...newUser, phone_number: e.target.value })}
                pattern="^\+?1?\d{9,15}$"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="email"
                placeholder="Email Address"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="text"
                placeholder="Address (min. 5 characters)"
                value={newUser.address}
                onChange={(e) => setNewUser({ ...newUser, address: e.target.value })}
                minLength={5}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="date"
                placeholder="Birthday"
                value={newUser.birthday}
                onChange={(e) => setNewUser({ ...newUser, birthday: e.target.value })}
                max={new Date().toISOString().split('T')[0]}
                min="1900-01-01"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="password"
                placeholder="Password (min. 8 characters)"
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                minLength={8}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>
          </div>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setIsAddUserModalOpen(false)}
              className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
            >
              Cancel
            </button>
            <button
              onClick={handleAddUser}
              className="px-6 py-2 bg-[#8E44AD] text-white rounded-full hover:bg-opacity-90 transition-all"
            >
              Add User
            </button>
          </div>
        </div>
      </div>
    )}

{/* Request Meeting Modal */}
{isRequestMeetingModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-xl shadow-xl w-[450px] border-t-4 border-[#1E3799] max-h-[90vh] overflow-y-auto">
      {/* Header */}
      <div className="flex items-center mb-6">
        <div className="p-3 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
          <Building className="text-[#1E3799]" size={24} />
        </div>
        <div>
          <h2 className="text-xl font-bold text-[#1E3799]">Request Meeting Room</h2>
          <p className="text-sm text-gray-600">Fill in the meeting details below</p>
        </div>
      </div>

      {/* Form */}
      <div className="space-y-4">
        {/* Title Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Title Prefix & Name
            <span className="text-red-500 ml-1">*</span>
          </label>
          
          <div className="flex space-x-2">
            {/* Prefix Selection */}
            <div className="flex rounded-lg overflow-hidden border border-gray-200">
              <button
                onClick={() => setTitlePrefix('MK')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  titlePrefix === 'MK' 
                    ? 'bg-[#1E3799] text-white' 
                    : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                }`}
              >
                MK
              </button>
              <button
                onClick={() => setTitlePrefix('Hangout')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  titlePrefix === 'Hangout' 
                    ? 'bg-[#1E3799] text-white' 
                    : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                }`}
              >
                Hangout
              </button>
            </div>

            {/* Title Input */}
            <div className="flex-1">
              <input
                type="text"
                placeholder="Enter meeting title"
                value={meetingRequest.title.replace(/^(MK|Hangout)\s*/, '')}
                onChange={(e) => handlePrefixedTitleChange(
                  e.target.value,
                  setMeetingRequest,
                  meetingRequest
                )}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-[#1E3799] 
                  focus:border-transparent bg-gray-50 hover:bg-gray-100 transition-all duration-200"
                minLength={FORM_LIMITS.TITLE_MIN}
                maxLength={FORM_LIMITS.TITLE_MAX}
              />
            </div>
          </div>
        </div>

        {/* Description Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Description
            <span className="text-red-500 ml-1">*</span>
          </label>
          <textarea
            placeholder="Enter meeting description"
            value={meetingRequest.description}
            onChange={(e) => setMeetingRequest({ ...meetingRequest, description: e.target.value })}
            className="w-full p-3 border rounded-lg h-32 focus:ring-2 focus:ring-[#1E3799] 
              focus:border-transparent resize-none bg-gray-50 hover:bg-gray-100 transition-all duration-200"
            minLength={FORM_LIMITS.DESCRIPTION_MIN}
            maxLength={FORM_LIMITS.DESCRIPTION_MAX}
          />
          <p className="text-xs text-gray-500 flex justify-between">
            <span>{meetingRequest.description.length} characters</span>
            <span>{FORM_LIMITS.DESCRIPTION_MAX - meetingRequest.description.length} remaining</span>
          </p>
        </div>

        {/* DateTime Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Meeting Date & Time
            <span className="text-red-500 ml-1">*</span>
          </label>
          <div className="relative">
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <Calendar className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="datetime-local"
              value={meetingRequest.meeting_datetime}
              onChange={(e) => setMeetingRequest({ ...meetingRequest, meeting_datetime: e.target.value })}
              className="w-full p-3 pl-12 border rounded-lg focus:ring-2 focus:ring-[#1E3799] 
                focus:border-transparent bg-gray-50 hover:bg-gray-100 transition-all duration-200"
            />
          </div>
        </div>

        {/* Location Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Location
            <span className="text-red-500 ml-1">*</span>
          </label>
          <div className="relative">
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Enter meeting location"
              value={meetingRequest.location}
              onChange={(e) => setMeetingRequest({ ...meetingRequest, location: e.target.value })}
              className="w-full p-3 pl-12 border rounded-lg focus:ring-2 focus:ring-[#1E3799] 
                focus:border-transparent bg-gray-50 hover:bg-gray-100 transition-all duration-200"
              minLength={FORM_LIMITS.LOCATION_MIN}
              maxLength={FORM_LIMITS.LOCATION_MAX}
            />
          </div>
          <p className="text-xs text-gray-500 flex justify-between">
            <span>{meetingRequest.location.length} characters</span>
            <span>{FORM_LIMITS.LOCATION_MAX - meetingRequest.location.length} remaining</span>
          </p>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end gap-3 mt-6 pt-4 border-t border-gray-100">
        <button
          onClick={() => setIsRequestMeetingModalOpen(false)}
          className="px-6 py-2.5 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 
            transition-colors duration-200 flex items-center gap-2"
        >
          Cancel
        </button>
        <button
          onClick={handleRequestMeeting}
          disabled={!meetingRequest.title || !meetingRequest.description || 
            !meetingRequest.meeting_datetime || !meetingRequest.location}
          className="px-6 py-2.5 bg-[#1E3799] text-white rounded-lg hover:bg-opacity-90 
            transition-colors duration-200 flex items-center gap-2 disabled:opacity-50 
            disabled:cursor-not-allowed"
        >
          <Building className="w-4 h-4" />
          Submit Request
        </button>
      </div>
    </div>
  </div>
)}

{/* Manual Meeting Modal */}
{isManualMeetingModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-xl shadow-xl w-[450px] border-t-4 border-[#8E44AD] max-h-[90vh] overflow-y-auto">
      {/* Header */}
      <div className="flex items-center mb-6">
        <div className="p-3 bg-[#8E44AD] bg-opacity-10 rounded-full mr-4">
          <Calendar className="text-[#8E44AD]" size={24} />
        </div>
        <div>
          <h2 className="text-xl font-bold text-[#8E44AD]">Set Meeting Room Manually</h2>
          <p className="text-sm text-gray-600">Create a manual meeting entry</p>
        </div>
      </div>

      {/* Form */}
      <div className="space-y-4">
        {/* Title Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Title
            <span className="text-red-500 ml-1">*</span>
          </label>
          
          <div className="flex space-x-2">
            {/* Prefix Selection */}
            <div className="flex rounded-lg overflow-hidden border border-gray-200">
              <button
                onClick={() => setTitlePrefix('MK')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  titlePrefix === 'MK' 
                    ? 'bg-[#1E3799] text-white' 
                    : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                }`}
              >
                MK
              </button>
              <button
                onClick={() => setTitlePrefix('Hangout')}
                className={`px-4 py-2 text-sm font-medium transition-colors ${
                  titlePrefix === 'Hangout' 
                    ? 'bg-[#1E3799] text-white' 
                    : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                }`}
              >
                Hangout
              </button>
            </div>

            {/* Title Input */}
            <div className="flex-1">
              <input
                type="text"
                placeholder="Enter meeting title"
                value={manualMeeting.request.title.replace(/^(MK|Hangout)\s*/, '')}
                onChange={(e) => setManualMeeting({
                  ...manualMeeting,
                  request: {
                    ...manualMeeting.request,
                    title: `${titlePrefix} ${e.target.value}`
                  }
                })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-[#1E3799] 
                  focus:border-transparent bg-gray-50 hover:bg-gray-100 transition-all duration-200"
                minLength={FORM_LIMITS.TITLE_MIN}
                maxLength={FORM_LIMITS.TITLE_MAX}
              />
            </div>
          </div>
        </div>
        {/* Description Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Description
            <span className="text-red-500 ml-1">*</span>
          </label>
          <textarea
            placeholder="Enter meeting description"
            value={manualMeeting.request.description}
            onChange={(e) => setManualMeeting({
              ...manualMeeting,
              request: { ...manualMeeting.request, description: e.target.value }
            })}
            className="w-full p-3 border rounded-lg h-32 focus:ring-2 focus:ring-[#8E44AD] 
              focus:border-transparent resize-none bg-gray-50 hover:bg-gray-100 transition-all duration-200"
            minLength={FORM_LIMITS.DESCRIPTION_MIN}
            maxLength={FORM_LIMITS.DESCRIPTION_MAX}
          />
          <p className="text-xs text-gray-500 flex justify-between">
            <span>{manualMeeting.request.description.length} characters</span>
            <span>{FORM_LIMITS.DESCRIPTION_MAX - manualMeeting.request.description.length} remaining</span>
          </p>
        </div>

        {/* DateTime Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Meeting Date & Time
            <span className="text-red-500 ml-1">*</span>
          </label>
          <div className="relative">
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <Calendar className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="datetime-local"
              value={manualMeeting.request.meeting_datetime}
              onChange={(e) => setManualMeeting({
                ...manualMeeting,
                request: { ...manualMeeting.request, meeting_datetime: e.target.value }
              })}
              className="w-full p-3 pl-12 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] 
                focus:border-transparent bg-gray-50 hover:bg-gray-100 transition-all duration-200"
            />
          </div>
        </div>

        {/* Location Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Location
            <span className="text-red-500 ml-1">*</span>
          </label>
          <div className="relative">
            <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Enter meeting location"
              value={manualMeeting.request.location}
              onChange={(e) => setManualMeeting({
                ...manualMeeting,
                request: { ...manualMeeting.request, location: e.target.value }
              })}
              className="w-full p-3 pl-12 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] 
                focus:border-transparent bg-gray-50 hover:bg-gray-100 transition-all duration-200"
              minLength={FORM_LIMITS.LOCATION_MIN}
              maxLength={FORM_LIMITS.LOCATION_MAX}
            />
          </div>
          <p className="text-xs text-gray-500 flex justify-between">
            <span>{manualMeeting.request.location.length} characters</span>
            <span>{FORM_LIMITS.LOCATION_MAX - manualMeeting.request.location.length} remaining</span>
          </p>
        </div>

        {/* Response Input */}
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-700 flex items-center">
            Response
            <span className="text-red-500 ml-1">*</span>
          </label>
          <textarea
            placeholder="Enter meeting response"
            value={manualMeeting.response.response}
            onChange={(e) => setManualMeeting({
              ...manualMeeting,
              response: { response: e.target.value }
            })}
            className="w-full p-3 border rounded-lg h-32 focus:ring-2 focus:ring-[#8E44AD] 
              focus:border-transparent resize-none bg-gray-50 hover:bg-gray-100 transition-all duration-200"
            minLength={FORM_LIMITS.RESPONSE_MIN}
            maxLength={FORM_LIMITS.RESPONSE_MAX}
          />
          <p className="text-xs text-gray-500 flex justify-between">
            <span>{manualMeeting.response.response.length} characters</span>
            <span>{FORM_LIMITS.RESPONSE_MAX - manualMeeting.response.response.length} remaining</span>
          </p>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end gap-3 mt-6 pt-4 border-t border-gray-100">
        <button
          onClick={() => setIsManualMeetingModalOpen(false)}
          className="px-6 py-2.5 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 
            transition-colors duration-200 flex items-center gap-2"
        >
          Cancel
        </button>
        <button
          onClick={handleManualMeeting}
          disabled={!manualMeeting.request.title || !manualMeeting.request.description || 
            !manualMeeting.request.meeting_datetime || !manualMeeting.request.location || 
            !manualMeeting.response.response}
          className="px-6 py-2.5 bg-[#8E44AD] text-white rounded-lg hover:bg-opacity-90 
            transition-colors duration-200 flex items-center gap-2 disabled:opacity-50 
            disabled:cursor-not-allowed"
        >
          <Calendar className="w-4 h-4" />
          Submit Meeting</button>
          </div>
        </div>
      </div>
    )}

    {/* Reset Password Modal */}
    {isResetPasswordModalOpen && editingUser && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#FFB75E]">
          <h2 className="text-xl font-bold mb-2 text-[#FFB75E]">Reset Password</h2>
          <p className="text-gray-600 mb-4">Reset password for {editingUser.name}</p>
          
          <div className="space-y-4">
            <div>
              <div className="relative">
                <input 
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#FFB75E] 
                    focus:border-transparent pr-10"
                  minLength={8}
                  required
                />
                {/* Simple password strength indicator */}
                <div className="absolute right-3 top-1/2 -translate-y-1/2">
                  {newPassword.length >= 8 ? (
                    <CheckCircle className="w-5 h-5 text-green-500" />
                  ) : (
                    <AlertTriangle className="w-5 h-5 text-gray-300" />
                  )}
                </div>
              </div>

              {/* Password requirements */}
              <div className="mt-2 text-xs space-y-1">
                <p className={`flex items-center ${
                  newPassword.length >= 8 ? 'text-green-600' : 'text-gray-500'
                }`}>
                  <CheckCircle className={`w-3 h-3 mr-1 ${
                    newPassword.length >= 8 ? 'text-green-600' : 'text-gray-400'
                  }`} />
                  At least 8 characters
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => {
                setIsResetPasswordModalOpen(false);
                setNewPassword('');
              }}
              className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
            >
              Cancel
            </button>
            <button
              onClick={() => handleResetPassword(editingUser.id)}
              className="px-6 py-2 bg-[#FFB75E] text-white rounded-full hover:bg-opacity-90 
                transition-all disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    )}
    {/* Absence Token Modal */}
    {isAbsenceTokenModalOpen && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96">
          <h2 className="text-xl font-bold mb-2 text-[#2ECC71]">Generate Absence Token</h2>
          <p className="text-gray-600 mb-4">
            Generate a 6-character token for attendance tracking
          </p>

          <div className="space-y-4">
            <div>
              <input 
                type="text"
                placeholder="Enter 6-character token"
                value={customToken}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  if (value.length <= 6 && /^[A-Z0-9]*$/.test(value)) {
                    setCustomToken(value);
                  }
                }}
                maxLength={6}
                className="w-full h-12 px-4 border-2 border-gray-200 rounded-lg 
                  focus:ring-2 focus:ring-[#2ECC71] focus:border-[#2ECC71] 
                  uppercase tracking-wider font-mono text-center text-lg"
                style={{
                  lineHeight: '48px', // Match the height
                  padding: '0 16px',  // Consistent padding
                  appearance: 'none'  // Remove browser styling
                }}
                required
              />

              {/* Progress indicators */}
              <div className="flex justify-between px-1 mt-2">
                <span className="text-sm text-green-500">
                  {customToken.length}/6 characters
                </span>
                <span className="text-sm text-gray-500">
                  {/^[A-Z0-9]*$/.test(customToken) ? '✓ Valid format' : ''}
                </span>
              </div>
            </div>

            {/* Example tokens */}
            <div className="bg-gray-50 p-3 rounded-lg mt-4">
              <p className="text-xs text-gray-500 mb-2">Example valid tokens:</p>
              <div className="flex gap-2">
                {['ABC123', 'XYZ789', '123ABC'].map((example) => (
                  <button
                    key={example}
                    onClick={() => setCustomToken(example)}
                    className="px-3 py-1.5 text-sm bg-white border border-gray-200 
                      rounded-md hover:border-[#2ECC71] transition-colors font-mono"
                  >
                    {example}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => {
                setIsAbsenceTokenModalOpen(false);
                setCustomToken('');
                setSelectedUserId(null);
              }}
              className="px-6 py-2.5 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 
                transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmitCustomToken}
              className="px-6 py-2.5 bg-[#2ECC71] text-white rounded-lg 
                hover:bg-opacity-90 transition-colors disabled:opacity-50 
                disabled:cursor-not-allowed"
            >
              Generate Token
            </button>
          </div>
        </div>
      </div>
    )}

    {/* Edit User Modal */}
    {isEditUserModalOpen && editingUser && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#1E3799]">
          <h2 className="text-xl font-bold mb-6 text-[#1E3799]">Edit User</h2>
          <div className="space-y-4">
            <div>
              <input 
                type="text"
                placeholder="Name (letters, spaces, and hyphens only)"
                value={editingUser.name}
                onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })}
                pattern="^[a-zA-Z\s-]{2,100}$"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="tel"
                placeholder="Phone Number (+62/0)"
                value={editingUser.phone_number}
                onChange={(e) => setEditingUser({ ...editingUser, phone_number: e.target.value })}
                pattern="^\+?1?\d{9,15}$"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="email"
                placeholder="Email Address"
                value={editingUser.email}
                onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="text"
                placeholder="Address (min. 5 characters)"
                value={editingUser.address}
                onChange={(e) => setEditingUser({ ...editingUser, address: e.target.value })}
                minLength={5}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>

            <div>
              <input 
                type="date"
                placeholder="Birthday"
                value={editingUser.birthday}
                onChange={(e) => setEditingUser({ ...editingUser, birthday: e.target.value })}
                max={new Date().toISOString().split('T')[0]}
                min="1900-01-01"
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent
                  invalid:border-red-300 invalid:text-red-900 invalid:placeholder-red-300"
                required
              />
            </div>
          </div>
          <div className="flex justify-end gap-3 mt-6">
            <button
              onClick={() => setIsEditUserModalOpen(false)}
              className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
            >
              Cancel
            </button>
            <button
              onClick={handleUpdateUser}
              className="px-6 py-2 bg-[#1E3799] text-white rounded-full hover:bg-opacity-90 transition-all"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    )}
    </div>
  );
};

export default MaintainerDashboard;