export const SecurityConfig = {
  PASSWORD_PATTERN: /^.{8,}$/,
  PHONE_PATTERN: /^(\+62|0)[0-9]{9,14}$/,
  NAME_PATTERN: /^[a-zA-Z\s-]{2,100}$/,
  EMAIL_PATTERN: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  ABSENCE_TOKEN_PATTERN: /^[A-Z0-9]{6}$/,
};

export const FORM_LIMITS = {
  // Meeting Room Requests
  TITLE_MIN: 5,
  TITLE_MAX: 100,
  DESCRIPTION_MIN: 10,
  DESCRIPTION_MAX: 500,
  LOCATION_MIN: 3,
  LOCATION_MAX: 100,
  
  // Manual Meeting Response
  RESPONSE_MIN: 10,
  RESPONSE_MAX: 500, // Added this line
  
  // Sermon Notes
  NOTE_TITLE_MIN: 3,
  NOTE_TITLE_MAX: 100,
  NOTE_DESCRIPTION_MIN: 10,
  NOTE_DESCRIPTION_MAX: 2000,
};

export const InputValidators = {
  validatePassword(password: string): string | null {
      if (!SecurityConfig.PASSWORD_PATTERN.test(password)) {
          return "Password must be at least 8 characters";
      }
      return null;
  },

  validatePhone(phone: string): string | null {
      if (!SecurityConfig.PHONE_PATTERN.test(phone)) {
          return "Invalid phone number format";
      }
      return null;
  },

  validateEmail(email: string): string | null {
      if (!SecurityConfig.EMAIL_PATTERN.test(email)) {
          return "Invalid email format";
      }
      return null;
  },

  validateName(name: string): string | null {
      if (!SecurityConfig.NAME_PATTERN.test(name.trim())) {
          return "Name must be 2-100 characters and contain only letters, spaces, and hyphens";
      }
      return null;
  },

  validateAddress(address: string): string | null {
      if (address.trim().length < 5) {
          return "Address must be at least 5 characters long";
      }
      return null;
  },

  validateBirthday(birthday: string): string | null {
      const birthdayDate = new Date(birthday);
      const currentDate = new Date();
      const minBirthday = new Date(1900, 0, 1);
      const maxBirthday = new Date(
          currentDate.getFullYear() - 10,
          currentDate.getMonth(),
          currentDate.getDate()
      );

      if (birthdayDate < minBirthday || birthdayDate > maxBirthday) {
          return "You must be at least 10 years old and born after 1900";
      }
      return null;
  },

  validateAbsenceToken(token: string): string | null {
      if (!token) {
          return "Token is required";
      }
      if (!SecurityConfig.ABSENCE_TOKEN_PATTERN.test(token)) {
          return "Token must be 6 characters long and contain only uppercase letters and numbers";
      }
      return null;
  },

  validateMeetingTitle(title: string): string | null {
      if (title.trim().length < FORM_LIMITS.TITLE_MIN) {
          return `Title must be at least ${FORM_LIMITS.TITLE_MIN} characters`;
      }
      if (title.trim().length > FORM_LIMITS.TITLE_MAX) {
          return `Title must not exceed ${FORM_LIMITS.TITLE_MAX} characters`;
      }
      return null;
  },

  validateMeetingDescription(description: string): string | null {
      if (description.trim().length < FORM_LIMITS.DESCRIPTION_MIN) {
          return `Description must be at least ${FORM_LIMITS.DESCRIPTION_MIN} characters`;
      }
      if (description.trim().length > FORM_LIMITS.DESCRIPTION_MAX) {
          return `Description must not exceed ${FORM_LIMITS.DESCRIPTION_MAX} characters`;
      }
      return null;
  },

  validateMeetingLocation(location: string): string | null {
      if (location.trim().length < FORM_LIMITS.LOCATION_MIN) {
          return `Location must be at least ${FORM_LIMITS.LOCATION_MIN} characters`;
      }
      if (location.trim().length > FORM_LIMITS.LOCATION_MAX) {
          return `Location must not exceed ${FORM_LIMITS.LOCATION_MAX} characters`;
      }
      return null;
  },

  validateMeetingDateTime(dateTime: string): string | null {
      const meetingDate = new Date(dateTime);
      const currentDate = new Date();
      
      if (isNaN(meetingDate.getTime())) {
          return "Invalid date format";
      }
      
      if (meetingDate < currentDate) {
          return "Meeting date cannot be in the past";
      }
      
      const maxDate = new Date();
      maxDate.setMonth(maxDate.getMonth() + 3); // Allow booking up to 3 months ahead
      
      if (meetingDate > maxDate) {
          return "Meeting cannot be scheduled more than 3 months in advance";
      }
      
      return null;
  },

  validateMeetingResponse(response: string): string | null {
      if (response.trim().length < FORM_LIMITS.RESPONSE_MIN) {
          return `Response must be at least ${FORM_LIMITS.RESPONSE_MIN} characters`;
      }
      if (response.trim().length > FORM_LIMITS.RESPONSE_MAX) {
          return `Response must not exceed ${FORM_LIMITS.RESPONSE_MAX} characters`;
      }
      return null;
  }
};