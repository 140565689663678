import React, { useState, useEffect } from 'react';
import { 
  Home, 
  User, 
  Users, 
  UserPlus,
  Edit2,
  Trash2,
  Key,
  Mail,
  ChevronDown,
  ChevronUp,
  CheckCircle,
  Quote,
  Shield,
  MapPin,
  Phone,
  IdCard,
  UserCircle2,
  LogOut,
  Building,
  Flame,
  Cake,
  BarChart2,
  FileText,
  Clock
} from 'lucide-react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend,
  ResponsiveContainer 
} from 'recharts';
import { api } from '../../services/api';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BirthdaySlider from '../../components/BirthdaySlider';
import { InputValidators } from '../../utils/validationUtils';
import EnhancedLoadingScreen from '../../components/EnhancedLoadingScreen';

interface UserDetails {
  name: string;
  username: string;
  maintainer: {
    username: string;
  };
}

interface ReportData {
  maintainer_id: number;
  maintainer_name: string;
  meetings: Array<{
    title: string;
    description: string;
    location: string;
    meeting_datetime: string;
    id: number;
    maintainer_id: number;
    status: string;
    response: string;
    created_at: string;
    expires_at: string;
    maintainer_name: string;
  }>;
  absences: Array<{
    user_id: number;
    recorded_at: string;
  }>;
}

export interface Birthday {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string | null;
  maintainer?: {
    name: string;
  };
  super_maintainer?: {
    name: string;
  };
  admin?: {
    name: string;
  };
  role: string;
  service_info: any | null;
}

interface DashboardCardProps {
  children: React.ReactNode;
  className?: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ children, className = '' }) => (
  <div className={`bg-white rounded-xl shadow-lg p-6 transition-all duration-300 
    hover:shadow-xl hover:border-l-4 hover:border-[#1E3799] ${className}`}>
    {children}
  </div>
);

interface SuperMaintainerProfile {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string;
  admin?: {
    name: string;
    phone_number: string;
  };
  role: string;
}

interface BibleVerse {
  bookname: string;
  chapter: string;
  verse: string;
  text: string;
}

interface MaintainedMaintainer {
  id: number;
  name: string;
  username: string;
  phone_number: string;
  email: string;
  birthday: string;
  users: Array<{
    id: number;
    name: string;
    phone_number: string;
    email: string;
  }>;
}

interface AvailableMaintainer {
  id: number;
  name: string;
  username: string;
  phone_number: string;
  email: string;
}

interface Invitation {
  id: number;
  admin_name: string;
  details: string;
  created_at: string;
}

interface MaintainerFormData {
  name: string;
  username: string;
  phone_number: string;
  email: string;
  birthday: string;
  password: string;
}

const SuperMaintainerDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [profile, setProfile] = useState<SuperMaintainerProfile | null>(null);
  const [bibleVerse, setBibleVerse] = useState<BibleVerse | null>(null);
  const [maintainers, setMaintainers] = useState<MaintainedMaintainer[]>([]);
  const [availableMaintainers, setAvailableMaintainers] = useState<AvailableMaintainer[]>([]);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [expandedMaintainerId, setExpandedMaintainerId] = useState<number | null>(null);
  
  const [isAddMaintainerModalOpen, setIsAddMaintainerModalOpen] = useState(false);
  const [isEditMaintainerModalOpen, setIsEditMaintainerModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  
  const [newMaintainer, setNewMaintainer] = useState<MaintainerFormData>({
    name: '',
    username: '',
    phone_number: '',
    email: '',
    birthday: '',
    password: ''
  });
  const [editingMaintainer, setEditingMaintainer] = useState<MaintainedMaintainer | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [removingId, setRemovingId] = useState<number | null>(null);
  const [birthdays, setBirthdays] = useState<Birthday[]>([]);
  const [reportData, setReportData] = useState<ReportData[]>([]);
  const [reportStartDate, setReportStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]
  );
  const [reportEndDate, setReportEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [expandedReportMaintainer, setExpandedReportMaintainer] = useState<number | null>(null);
  const [graphData, setGraphData] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useState<Record<number, UserDetails>>({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (activeTab === 'report') {
      fetchReportData();
    }
  }, [activeTab, reportStartDate, reportEndDate]);

  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        const response = await api.getTodaysBirthdays();
        setBirthdays(response.data);
      } catch (error) {
        console.error('Failed to load birthdays:', error);
        toast.error('Failed to load birthdays');
      }
    };
    fetchBirthdays();
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const profileResponse = await api.getSuperMaintainerProfile();
        setProfile(profileResponse.data);

        const verseResponse = await axios.get('https://labs.bible.org/api/?passage=random&type=json&callback=myCallback', {
          transformResponse: [(data) => {
            const jsonMatch = data.match(/myCallback\((.*)\)/);
            return jsonMatch ? JSON.parse(jsonMatch[1]) : data;
          }]
        });
        
        if (Array.isArray(verseResponse.data) && verseResponse.data.length > 0) {
          setBibleVerse(verseResponse.data[0]);
        }

        const maintainersResponse = await api.getSuperMaintainedMaintainers();
        setMaintainers(maintainersResponse.data);

        const availableResponse = await api.getAvailableMaintainers();
        setAvailableMaintainers(availableResponse.data);

        if (!profileResponse.data.admin) {
          const invitationsResponse = await api.getSuperMaintainerInvitations();
          setInvitations(invitationsResponse.data);
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {
        toast.error('Failed to load dashboard data');
      }
    };

    fetchInitialData();
  }, []);

  const fetchUserName = async (userId: number) => {
    try {
      const response = await api.getUser(userId);
      setUserDetails(prev => ({...prev, [userId]: response.data}));
    } catch (error) {
      console.error(`Failed to fetch user ${userId}`);
    }
  };

  useEffect(() => {
    if (reportData.length > 0) {
      const userIds = new Set(
        reportData.flatMap(m => m.absences.map(a => a.user_id))
      );
      userIds.forEach(fetchUserName);
    }
  }, [reportData]);

  if (isLoading) {
    return <EnhancedLoadingScreen />;
  }

  const fetchReportData = async () => {
    try {
      const response = await api.getMaintainerReport(reportStartDate, reportEndDate);
      setReportData(response.data);
      
      const mkCount = response.data.reduce((acc: number, maintainer: ReportData) => 
        acc + maintainer.meetings.filter((m) => m.title.toLowerCase().includes('mk')).length, 0
      );
      const hangoutCount = response.data.reduce((acc: number, maintainer: ReportData) => 
        acc + maintainer.meetings.filter((m) => m.title.toLowerCase().includes('hangout')).length, 0
      );
      
      setGraphData([
        { name: 'MK Sessions', count: mkCount, fill: '#1E3799' },
        { name: 'Hangouts', count: hangoutCount, fill: '#8E44AD' }
      ]);
    } catch (error) {
      toast.error('Failed to fetch report data');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
    toast.success('Logged out successfully');
  };

  const handleAddMaintainer = async () => {
    const nameError = InputValidators.validateName(newMaintainer.name);
    if (nameError) {
      toast.error(nameError);
      return;
    }
  
    const phoneError = InputValidators.validatePhone(newMaintainer.phone_number);
    if (phoneError) {
      toast.error(phoneError);
      return;
    }
  
    const emailError = InputValidators.validateEmail(newMaintainer.email);
    if (emailError) {
      toast.error(emailError);
      return;
    }
  
    const birthdayError = InputValidators.validateBirthday(newMaintainer.birthday);
    if (birthdayError) {
      toast.error(birthdayError);
      return;
    }
  
    const passwordError = InputValidators.validatePassword(newMaintainer.password);
    if (passwordError) {
      toast.error(passwordError);
      return;
    }
    
    try {
      await api.createMaintainer(newMaintainer);
      const maintainersResponse = await api.getSuperMaintainedMaintainers();
      setMaintainers(maintainersResponse.data);
      setIsAddMaintainerModalOpen(false);
      setNewMaintainer({ name: '', username: '', phone_number: '', email: '',birthday: '', password: '' });
      toast.success('Maintainer added successfully');
    } catch (error) {
      toast.error('Failed to add maintainer');
    }
  };

  const handleUpdateMaintainer = async () => {
    if (!editingMaintainer) return;
      // Validate all fields using InputValidators
      const nameError = InputValidators.validateName(editingMaintainer.name);
      if (nameError) {
        toast.error(nameError);
        return;
      }
    
      const phoneError = InputValidators.validatePhone(editingMaintainer.phone_number);
      if (phoneError) {
        toast.error(phoneError);
        return;
      }
    
      const emailError = InputValidators.validateEmail(editingMaintainer.email);
      if (emailError) {
        toast.error(emailError);
        return;
      }
    
      const birthdayError = InputValidators.validateBirthday(editingMaintainer.birthday);
      if (birthdayError) {
        toast.error(birthdayError);
        return;
      }

    try {
      await api.updateMaintainer(editingMaintainer.id, editingMaintainer);
      const maintainersResponse = await api.getSuperMaintainedMaintainers();
      setMaintainers(maintainersResponse.data);
      setIsEditMaintainerModalOpen(false);
      setEditingMaintainer(null);
      toast.success('Maintainer updated successfully');
    } catch (error) {
      toast.error('Failed to update maintainer');
    }
  };

  const handleResetPassword = async (maintainerId: number) => {
    const passwordError = InputValidators.validatePassword(newPassword);

    if (passwordError) {
      toast.error(passwordError);
      return;
    }

    try {
      await api.resetMaintainerPassword(maintainerId, { new_password: newPassword });
      setIsResetPasswordModalOpen(false);
      setNewPassword('');
      toast.success('Password reset successfully');
    } catch (error) {
      toast.error('Failed to reset password');
    }
  };

  const handleInviteMaintainer = async (maintainerId: number) => {
    try {
      await api.inviteMaintainer(maintainerId);
      toast.success('Invitation sent successfully');
    } catch (error) {
      toast.error('Failed to send invitation');
    }
  };

  const handleInvitationResponse = async (invitationId: number, accepted: boolean) => {
    try {
      await api.respondToSuperMaintainerInvitation(invitationId, accepted);
      const invitationsResponse = await api.getSuperMaintainerInvitations();
      setInvitations(invitationsResponse.data);
      toast.success(accepted ? 'Invitation accepted' : 'Invitation declined');
    } catch (error) {
      toast.error('Failed to respond to invitation');
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'home':
        return (
          <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
            <div className="max-w-xl mx-auto space-y-6">
              <DashboardCard>
                <div className="flex items-center mb-4">
                  <div className="p-3 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
                    <Flame className="text-[#1E3799]" size={32} />
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                      Hello Ketua Rayon, {profile?.name}!
                    </h1>
                    <p className="text-gray-600">
                      Welcome to Youth Fire Community
                    </p>
                  </div>
                </div>
              </DashboardCard>

              {bibleVerse && (
                <DashboardCard className="hover:bg-[#FFF9F5]">
                  <div className="flex items-start">
                    <div className="p-2 bg-[#8E44AD] bg-opacity-10 rounded-full mr-4">
                      <Quote className="text-[#8E44AD]" size={24} />
                    </div>
                    <div>
                      <p className="italic text-gray-700 mb-3 font-serif text-lg">
                        "{bibleVerse.text}"
                      </p>
                      <p className="text-[#8E44AD] font-medium">
                        {bibleVerse.bookname} {bibleVerse.chapter}:{bibleVerse.verse}
                      </p>
                    </div>
                  </div>
                </DashboardCard>
              )}
              <BirthdaySlider birthdays={birthdays} />
            </div>
          </div>
        );

      case 'profile':
        return (
          <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
            <div className="max-w-xl mx-auto">
              <DashboardCard className="mb-6">
                <div className="flex items-center">
                  <div className="p-4 bg-[#1E3799] bg-opacity-10 rounded-full mr-6">
                    <UserCircle2 className="text-[#1E3799]" size={48} />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                      {profile?.name}
                    </h2>
                    <p className="text-gray-600">Ketua Rayon</p>
                  </div>
                </div>
              </DashboardCard>

              <DashboardCard className="mb-6">
                <h3 className="text-xl font-semibold text-[#1E3799] mb-6 flex items-center">
                  <IdCard className="mr-2" size={24} />
                  Personal Information
                </h3>
                
                <div className="space-y-6">
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <Phone className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Phone Number</p>
                      <p className="text-gray-600">{profile?.phone_number}</p>
                    </div>
                  </div>
                  
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <MapPin className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Address</p>
                      <p className="text-gray-600">{profile?.address}</p>
                    </div>
                  </div>
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <Cake className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Birthday</p>
                      <p className="text-gray-600">{profile?.birthday}</p>
                    </div>
                  </div>
                </div>
              </DashboardCard>

              {profile?.admin && (
                <DashboardCard className="mb-6">
                  <h3 className="text-xl font-semibold text-[#1E3799] mb-6 flex items-center">
                    <Shield className="mr-2" size={24} />
                    Leadership Connection
                  </h3>
                  
                  <div className="flex items-center p-4 bg-[#F5F6FA] rounded-lg">
                    <Shield className="text-[#8E44AD] mr-4" size={20} />
                    <div>
                      <p className="font-medium text-gray-700">Leader</p>
                      <p className="text-gray-600">{profile.admin.name}</p>
                      <p className="text-sm text-gray-500">{profile.admin.phone_number}</p>
                    </div>
                  </div>
                </DashboardCard>
              )}

              <div className="flex justify-center">
                <button 
                  onClick={handleLogout}
                  className="bg-[#1E3799] text-white px-8 py-3 rounded-full hover:bg-opacity-90 
                    transition-all duration-300 transform hover:scale-105 flex items-center gap-2 shadow-lg"
                >
                  <LogOut size={20} />
                  <span className="font-medium">Logout</span>
                </button>
              </div>
            </div>
          </div>
        );
        case 'report':
          return (
            <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
              <div className="max-w-4xl mx-auto space-y-6">
                <DashboardCard>
                  <div className="mb-6">
                    <h2 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                      Community Activity Report
                    </h2>
                    <div className="flex gap-4 mt-4">
                      <div className="flex flex-col">
                        <label className="text-sm text-gray-600 mb-1">Start Date</label>
                        <input
                          type="date"
                          value={reportStartDate}
                          onChange={(e) => setReportStartDate(e.target.value)}
                          className="p-2 border rounded-lg focus:ring-2 focus:ring-[#1E3799]"
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className="text-sm text-gray-600 mb-1">End Date</label>
                        <input
                          type="date"
                          value={reportEndDate}
                          onChange={(e) => setReportEndDate(e.target.value)}
                          className="p-2 border rounded-lg focus:ring-2 focus:ring-[#1E3799]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={graphData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="count" name="Number of Sessions" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </DashboardCard>
        
                <div className="space-y-4">
                  {reportData.map((maintainer) => (
                    <DashboardCard key={maintainer.maintainer_id} className="hover:shadow-lg transition-all">
                      <div 
                        onClick={() => setExpandedReportMaintainer(
                          expandedReportMaintainer === maintainer.maintainer_id ? null : maintainer.maintainer_id
                        )}
                        className="cursor-pointer"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-3">
                            <div className="p-2 bg-[#1E3799] bg-opacity-10 rounded-full">
                              <FileText className="text-[#1E3799]" size={24} />
                            </div>
                            <div>
                              <h3 className="font-semibold text-gray-800">
                                {maintainer.maintainer_name}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {maintainer.meetings.length} meetings, {maintainer.absences.length} attendances
                              </p>
                            </div>
                          </div>
                          {expandedReportMaintainer === maintainer.maintainer_id ? 
                            <ChevronUp className="text-gray-500" /> : 
                            <ChevronDown className="text-gray-500" />
                          }
                        </div>
        
                        {expandedReportMaintainer === maintainer.maintainer_id && (
                          <div className="mt-6 space-y-4">
                            {maintainer.meetings.map((meeting) => (
                              <div key={meeting.id} className="bg-gray-50 rounded-lg p-4">
                                <div className="flex justify-between items-start mb-3">
                                  <h4 className="font-medium text-[#1E3799]">{meeting.title}</h4>
                                  <span className={`px-3 py-1 rounded-full text-xs ${
                                    meeting.status === 'completed' ? 'bg-green-100 text-green-800' : 
                                    'bg-yellow-100 text-yellow-800'
                                  }`}>
                                    {meeting.status}
                                  </span>
                                </div>
                                <p className="text-gray-600 text-sm mb-3">{meeting.description}</p>
                                <div className="flex gap-4 text-sm text-gray-500">
                                  <span className="flex items-center gap-1">
                                    <MapPin size={16} />
                                    {meeting.location}
                                  </span>
                                  <span className="flex items-center gap-1">
                                    <Clock size={16} />
                                    {new Date(meeting.meeting_datetime).toLocaleString()}
                                  </span>
                                </div>
                                <div className="mt-3 pt-3 border-t">
                                  <p className="text-sm font-medium text-gray-700 mb-2">Attendances:</p>
                                  {maintainer.absences
                                    .filter(absence => 
                                      new Date(absence.recorded_at).toDateString() === 
                                      new Date(meeting.meeting_datetime).toDateString()
                                    )
                                    .map((absence, idx) => (
                                      <div key={idx} className="flex justify-between items-center py-2 px-3 bg-gray-50 rounded-md mb-2">
                                        <div className="flex items-center gap-2">
                                          <User size={14} className="text-gray-500" />
                                          <span className="text-sm text-gray-700">
                                            {userDetails[absence.user_id]?.name || 'Loading...'}
                                          </span>
                                        </div>
                                        <span className="text-xs text-gray-500">
                                          {new Date(absence.recorded_at).toLocaleTimeString()}
                                        </span>
                                      </div>
                                    ))
                                  }
                                  {maintainer.absences
                                    .filter(absence => 
                                      new Date(absence.recorded_at).toDateString() === 
                                      new Date(meeting.meeting_datetime).toDateString()
                                    ).length === 0 && (
                                    <p className="text-sm text-gray-500 italic">No attendances recorded</p>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </DashboardCard>
                  ))}
                </div>
              </div>
            </div>
          );
        case 'maintainers':
          return (
            <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
              <div className="max-w-xl mx-auto">
                <DashboardCard className="mb-6">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="p-3 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
                        <Shield className="text-[#1E3799]" size={32} />
                      </div>
                      <div>
                        <h2 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                          Ketua MK
                        </h2>
                        <p className="text-gray-600">
                          Managing {maintainers.length} community leaders
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={() => setIsAddMaintainerModalOpen(true)}
                      className="p-3 bg-[#1E3799] text-white px-4 py-2 rounded-full hover:bg-opacity-90 
                        transition-all duration-300 transform hover:scale-105 flex items-center gap-2"
                    >
                      <UserPlus size={18} />
                    </button>
                  </div>
                </DashboardCard>
  
                {maintainers.length === 0 ? (
                  <DashboardCard className="text-center py-12">
                    <Users className="text-gray-300 mx-auto mb-4" size={64} />
                    <p className="text-gray-500 font-medium">No Ketua MK available</p>
                    <p className="text-gray-400 mt-2">Add your first Ketua MK</p>
                  </DashboardCard>
                ) : (
                  <div className="space-y-4">
                    {maintainers.map(maintainer => (
                      <DashboardCard key={maintainer.id}>
                        <div
                          onClick={() => setExpandedMaintainerId(
                            expandedMaintainerId === maintainer.id ? null : maintainer.id
                          )}
                          className="cursor-pointer"
                        >
                          <div className="flex justify-between items-center">
                            <div className="flex items-center">
                              <div className="p-2 bg-[#8E44AD] bg-opacity-10 rounded-full mr-3">
                                <Shield className="text-[#8E44AD]" size={20} />
                              </div>
                              <div>
                                <h3 className="font-semibold text-gray-800">{maintainer.name}</h3>
                                <p className="text-sm text-gray-500">{maintainer.phone_number}</p>
                              </div>
                            </div>
                            {expandedMaintainerId === maintainer.id ? (
                              <ChevronUp className="text-gray-500" size={20} />
                            ) : (
                              <ChevronDown className="text-gray-500" size={20} />
                            )}
                          </div>
  
                          {expandedMaintainerId === maintainer.id && (
                            <div className="mt-4 pt-4 border-t border-gray-100">
                              <div className="space-y-4">
                                <div className="p-3 bg-[#F5F6FA] rounded-lg">
                                  <p className="text-sm font-medium text-gray-600">Email</p>
                                  <p className="text-gray-800">{maintainer.email}</p>
                                </div>
  
                                <div className="mt-4">
                                  <h4 className="font-medium text-[#1E3799] mb-3">Anak MK:</h4>
                                  {maintainer.users.map(user => (
                                    <div key={user.id} className="bg-[#F5F6FA] rounded-lg p-4 mb-2">
                                      <p className="font-medium text-gray-800">{user.name}</p>
                                      <p className="text-sm text-gray-500">{user.phone_number}</p>
                                      <p className="text-sm text-gray-500">{user.email}</p>
                                    </div>
                                  ))}
                                </div>
  
                                <div className="flex gap-3 mt-4">
                                  <button
                                    onClick={() => {
                                      setEditingMaintainer(maintainer);
                                      setIsEditMaintainerModalOpen(true);
                                    }}
                                    className="p-2 bg-[#1E3799] text-white rounded-full hover:bg-opacity-90 transition-all"
                                  >
                                    <Edit2 size={15} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setEditingMaintainer(maintainer);
                                      setIsResetPasswordModalOpen(true);
                                    }}
                                    className="p-2 bg-[#8E44AD] text-white rounded-full hover:bg-opacity-90 transition-all"
                                  >
                                    <Key size={16} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setRemovingId(maintainer.id);
                                      setShowConfirm(true);
                                    }}
                                    className="p-2 bg-red-500 text-white rounded-full hover:bg-opacity-90 transition-all"
                                  >
                                    <Trash2 size={16} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </DashboardCard>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
  
        case 'ketua-mk':
          return (
            <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
              <div className="max-w-xl mx-auto">
                <DashboardCard className="mb-6">
                  <div className="flex items-center">
                    <div className="p-3 bg-[#8E44AD] bg-opacity-10 rounded-full mr-4">
                      <Building className="text-[#8E44AD]" size={32} />
                    </div>
                    <div>
                      <h2 className="text-2xl font-bold bg-gradient-to-r from-[#8E44AD] to-[#1E3799] bg-clip-text text-transparent">
                        Available Ketua MK
                      </h2>
                      <p className="text-gray-600">
                        Find and invite new community leaders
                      </p>
                    </div>
                  </div>
                </DashboardCard>
  
                {availableMaintainers.length === 0 ? (
                  <DashboardCard className="text-center py-12">
                    <Users className="text-gray-300 mx-auto mb-4" size={64} />
                    <p className="text-gray-500">No available leaders at the moment</p>
                    <p className="text-gray-400 text-sm mt-2">Check back later</p>
                  </DashboardCard>
                ) : (
                  <div className="space-y-4">
                    {availableMaintainers.map(maintainer => (
                      <DashboardCard key={maintainer.id} className="hover:border-[#8E44AD]">
                        <div className="flex flex-col">
                          <div className="flex items-center">
                            <div className="p-2 bg-[#8E44AD] bg-opacity-10 rounded-full mr-3">
                              <Shield className="text-[#8E44AD]" size={24} />
                            </div>
                            <div>
                              <h3 className="font-semibold text-gray-800">{maintainer.name}</h3>
                              <div className="flex items-center text-sm text-gray-500 mt-1">
                                <Phone className="w-4 h-4 mr-1" />
                                {maintainer.phone_number}
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-center mt-4">
                            <button
                              onClick={() => handleInviteMaintainer(maintainer.id)}
                              className="p-3 bg-[#2ECC71] text-white rounded-full hover:bg-opacity-90 
                                transition-all duration-300 transform hover:scale-105 shadow-md"
                            >
                              <UserPlus size={18} />
                            </button>
                          </div>
                        </div>
                      </DashboardCard>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
  
        case 'invitations':
          return (
            <div className="p-4 pb-20 bg-[#FFF9F5] min-h-full">
              <div className="max-w-xl mx-auto">
                <DashboardCard className="mb-6">
                  <div className="flex items-center">
                    <div className="p-3 bg-[#1E3799] bg-opacity-10 rounded-full mr-4">
                      <Mail className="text-[#1E3799]" size={32} />
                    </div>
                    <div>
                      <h2 className="text-2xl font-bold bg-gradient-to-r from-[#1E3799] to-[#8E44AD] bg-clip-text text-transparent">
                        Invitations
                      </h2>
                      <p className="text-gray-600">
                        {invitations.length === 0 
                          ? "No new invitations" 
                          : `You have ${invitations.length} pending invitation${invitations.length > 1 ? 's' : ''}`}
                      </p>
                    </div>
                  </div>
                </DashboardCard>
  
                {invitations.length === 0 ? (
                  <DashboardCard className="text-center py-12">
                    <Mail className="text-gray-300 mx-auto mb-4" size={64} />
                    <p className="text-gray-500">No invitations at the moment</p>
                    <p className="text-gray-400 text-sm mt-2">Check back later for updates</p>
                  </DashboardCard>
                ) : (
                  <div className="space-y-4">
                    {invitations.map(invitation => (
                      <DashboardCard key={invitation.id} className="hover:border-[#1E3799]">
                        <div className="flex items-start">
                          <div className="p-2 bg-[#1E3799] bg-opacity-10 rounded-full mr-3">
                            <Shield className="text-[#1E3799]" size={24} />
                          </div>
                          <div className="flex-grow">
                            <div className="flex justify-between items-center">
                              <h3 className="font-semibold text-gray-800">
                                Invitation from {invitation.admin_name}
                              </h3>
                              <span className="text-xs text-gray-500">
                                {new Date(invitation.created_at).toLocaleDateString()}
                              </span>
                            </div>
                            <p className="text-gray-600 text-sm mt-2">{invitation.details}</p>
                            <div className="flex justify-end gap-3 mt-4">
                              <button 
                                onClick={() => handleInvitationResponse(invitation.id, true)}
                                className="flex items-center gap-2 px-4 py-2 bg-[#1E3799] text-white rounded-full 
                                  hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                              >
                                <CheckCircle size={16} />
                                Accept
                              </button>
                              <button 
                                onClick={() => handleInvitationResponse(invitation.id, false)}
                                className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-full 
                                  hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105"
                              >
                                <Trash2 size={16} />
                                Decline
                              </button>
                            </div>
                          </div>
                        </div>
                      </DashboardCard>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-screen bg-[#FFF9F5]">
      <Toaster position="top-right" />
      
      <div className="flex-grow overflow-y-auto">
        {renderContent()}
      </div>

      <nav className="bg-white border-t border-gray-100 py-3 px-4 flex justify-around fixed bottom-0 left-0 right-0 z-10">
        {[
          { id: 'home', icon: Home, label: 'Home' },
          { id: 'profile', icon: User, label: 'Profile' },
          { id: 'report', icon: BarChart2, label: 'Report' },
          { id: 'maintainers', icon: Shield, label: 'Ketua MK' },
          { id: 'ketua-mk', icon: Building, label: 'List MK' },
          ...((!profile?.admin) ? [{ id: 'invitations', icon: Mail, label: 'Invitations' }] : [])
        ].map(item => (
          <button 
            key={item.id}
            onClick={() => setActiveTab(item.id)} 
            className={`flex flex-col items-center transition-all duration-300 ${
              activeTab === item.id 
                ? 'text-[#1E3799] transform scale-110' 
                : 'text-gray-400 hover:text-[#1E3799]'
            }`}
          >
            <item.icon size={20} />
            <span className="text-xs mt-1 font-medium">{item.label}</span>
          </button>
        ))}
      </nav>

      <div className="h-16"></div>

      {isAddMaintainerModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#1E3799]">
            <h2 className="text-xl font-bold mb-6 text-[#1E3799]">Add New Ketua MK</h2>
            <div className="space-y-4">
              <input 
                type="text"
                placeholder="Name"
                value={newMaintainer.name}
                onChange={(e) => setNewMaintainer({ ...newMaintainer, name: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="text"
                placeholder="Username"
                value={newMaintainer.username}
                onChange={(e) => setNewMaintainer({ ...newMaintainer, username: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="text"
                placeholder="Phone Number"
                value={newMaintainer.phone_number}
                onChange={(e) => setNewMaintainer({ ...newMaintainer, phone_number: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="email"
                placeholder="Email"
                value={newMaintainer.email}
                onChange={(e) => setNewMaintainer({ ...newMaintainer, email: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="date"
                placeholder="Birthday"
                value={newMaintainer.birthday}
                onChange={(e) => setNewMaintainer({ ...newMaintainer, birthday: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="password"
                placeholder="Password"
                value={newMaintainer.password}
                onChange={(e) => setNewMaintainer({ ...newMaintainer, password: e.target.value })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => setIsAddMaintainerModalOpen(false)}
                className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={handleAddMaintainer}
                className="px-6 py-2 bg-[#1E3799] text-white rounded-full hover:bg-opacity-90 transition-all"
              >
                Add MK
              </button>
            </div>
          </div>
        </div>
      )}

      {isEditMaintainerModalOpen && editingMaintainer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#1E3799]">
            <h2 className="text-xl font-bold mb-6 text-[#1E3799]">Edit Ketua MK</h2>
            <div className="space-y-4">
              <input 
                type="text"
                placeholder="Name"
                value={editingMaintainer.name}
                onChange={(e) => setEditingMaintainer({ 
                  ...editingMaintainer, 
                  name: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="text"
                placeholder="Phone Number"
                value={editingMaintainer.phone_number}
                onChange={(e) => setEditingMaintainer({ 
                  ...editingMaintainer, 
                  phone_number: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="email"
                placeholder="Email"
                value={editingMaintainer.email}
                onChange={(e) => setEditingMaintainer({ 
                  ...editingMaintainer, 
                  email: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
              <input 
                type="date"
                placeholder="Birthday"
                value={editingMaintainer.birthday}
                onChange={(e) => setEditingMaintainer({ 
                  ...editingMaintainer, 
                  birthday: e.target.value 
                })}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#1E3799] focus:border-transparent"
              />
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => setIsEditMaintainerModalOpen(false)}
                className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={handleUpdateMaintainer}
                className="px-6 py-2 bg-[#1E3799] text-white rounded-full hover:bg-opacity-90 transition-all"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
      {isResetPasswordModalOpen && editingMaintainer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-[#8E44AD]">
            <h2 className="text-xl font-bold mb-6 text-[#8E44AD]">Reset Password</h2>
            <div className="space-y-4">
              <p className="text-gray-600">Reset password for {editingMaintainer.name}</p>
              <input 
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-[#8E44AD] focus:border-transparent"
              />
            </div>
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => {
                  setIsResetPasswordModalOpen(false);
                  setNewPassword('');
                }}
                className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={() => handleResetPassword(editingMaintainer.id)}
                className="px-6 py-2 bg-[#8E44AD] text-white rounded-full hover:bg-opacity-90 transition-all"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-xl w-96 border-t-4 border-red-500">
            <div className="text-center">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
                <Trash2 className="h-6 w-6 text-red-500" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">Confirm Removal</h3>
              <p className="text-sm text-gray-500">
                Are you sure you want to remove this Ketua MK? This action cannot be undone.
              </p>
            </div>
            <div className="flex justify-center gap-3 mt-6">
              <button
                onClick={() => setShowConfirm(false)}
                className="px-6 py-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  try {
                    await api.removeMaintainer(removingId!);
                    const maintainersResponse = await api.getSuperMaintainedMaintainers();
                    setMaintainers(maintainersResponse.data);
                    setShowConfirm(false);
                    toast.success('Ketua MK removed successfully');
                  } catch (error) {
                    toast.error('Failed to remove Ketua MK');
                  }
                }}
                className="px-6 py-2 bg-red-500 text-white rounded-full hover:bg-opacity-90 transition-all"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperMaintainerDashboard;