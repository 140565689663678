import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import MobileViewChecker from './components/MobileViewChecker';
import PrivateRoute from './components/PrivateRoute';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import UserDashboard from './pages/dashboards/UserDashboard';
import MaintainerDashboard from './pages/dashboards/MaintainerDashboard';
import SuperMaintainerDashboard from './pages/dashboards/SuperMaintainerDashboard';
import AdminDashboard from './pages/dashboards/AdminDashboard';
import ServiceDashboard from './pages/dashboards/ServiceDashboard';

function App() {
  return (
    <BrowserRouter>
      <MobileViewChecker>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          
          {/* Protected Routes with Role Checks */}
          <Route 
            path="/dashboard/user" 
            element={
              <PrivateRoute requiredRole="user">
                <UserDashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/maintainer" 
            element={
              <PrivateRoute requiredRole="maintainer">
                <MaintainerDashboard />
              </PrivateRoute>
            }
          />
          <Route 
            path="/dashboard/super-maintainer" 
            element={
              <PrivateRoute requiredRole="super_maintainer">
                <SuperMaintainerDashboard />
              </PrivateRoute>
            }
          />
          <Route 
            path="/dashboard/admin" 
            element={
              <PrivateRoute requiredRole="admin">
                <AdminDashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/dashboard/service" 
            element={
              <PrivateRoute requiredRole="service">
                <ServiceDashboard />
              </PrivateRoute>
            } 
          />
          
          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MobileViewChecker>
    </BrowserRouter>
  );
}

export default App;