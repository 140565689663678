import React from 'react';
import { Cake, ChevronLeft, ChevronRight } from 'lucide-react';
import { useState, useEffect } from 'react';

interface Birthday {
  id: number;
  name: string;
  phone_number: string;
  birthday: string;
  address: string | null;
  maintainer?: {
    name: string;
  };
  super_maintainer?: {
    name: string;
  };
  admin?: {
    name: string;
  };
  role: string;
  service_info: any | null;
}

interface BirthdaySliderProps {
  birthdays: Birthday[];
}

const BirthdaySlider: React.FC<BirthdaySliderProps> = ({ birthdays }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (birthdays.length > 1) {
        setCurrentIndex((prevIndex) => 
          prevIndex === birthdays.length - 1 ? 0 : prevIndex + 1
        );
      }
    }, 5000);

    return () => clearInterval(timer);
  }, [birthdays]);

  if (!birthdays || birthdays.length === 0) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-6">
        <div className="flex items-center justify-center space-x-3">
          <Cake className="text-purple-500" size={24} />
          <p className="text-gray-600">No birthdays today</p>
        </div>
      </div>
    );
  }

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? birthdays.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === birthdays.length - 1 ? 0 : prevIndex + 1
    );
  };

  const birthday = birthdays[currentIndex];

  return (
    <div className="bg-white rounded-xl shadow-lg p-6">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-4">
          <Cake className="text-purple-500" size={24} />
          <div>
            <h3 className="font-medium text-gray-800">Today's Birthday 🎉</h3>
            <p className="text-purple-600">{birthday.name}</p>
            <p className="text-sm text-gray-500">{birthday.birthday}</p>
          </div>
        </div>
        
        <div className="text-right space-y-1">
          {birthday.maintainer && (
            <div className="flex items-center justify-end space-x-2">
              <span className="text-sm text-gray-600">MK:</span>
              <span className="text-sm text-purple-500">{birthday.maintainer.name}</span>
            </div>
          )}
          {birthday.super_maintainer && (
            <div className="flex items-center justify-end space-x-2">
              <span className="text-sm text-gray-600">Rayon:</span>
              <span className="text-sm text-purple-500">{birthday.super_maintainer.name}</span>
            </div>
          )}
          {birthday.admin && (
            <div className="flex items-center justify-end space-x-2">
              <span className="text-sm text-gray-600">Leader:</span>
              <span className="text-sm text-purple-500">{birthday.admin.name}</span>
            </div>
          )}
        </div>
      </div>

      {birthdays.length > 1 && (
        <div className="flex items-center justify-center mt-4 space-x-4">
          <button 
            onClick={handlePrevious}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
          >
            <ChevronLeft className="text-gray-500" size={20} />
          </button>
          <div className="flex space-x-1">
            {birthdays.map((_, index) => (
              <div 
                key={index}
                className={`w-2 h-2 rounded-full ${
                  index === currentIndex ? 'bg-purple-500' : 'bg-gray-200'
                }`}
              />
            ))}
          </div>
          <button 
            onClick={handleNext}
            className="p-1 rounded-full hover:bg-gray-100 transition-colors"
          >
            <ChevronRight className="text-gray-500" size={20} />
          </button>
        </div>
      )}
    </div>
  );
};

export default BirthdaySlider;